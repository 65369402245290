import React from "react";
import styles from "../styles/logosStyles.module.css";

const Shopify = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_273)">
        <path
          d="M38.6137 10.6126C38.5835 10.3933 38.3914 10.2719 38.2326 10.2586C38.0742 10.2453 34.7204 9.99676 34.7204 9.99676C34.7204 9.99676 32.3912 7.68438 32.1356 7.42843C31.8798 7.17264 31.3802 7.25044 31.1863 7.30752C31.1578 7.31593 30.6774 7.46418 29.8828 7.71006C29.1048 5.47113 27.7316 3.41365 25.3159 3.41365C25.2492 3.41365 25.1806 3.41635 25.1119 3.42026C24.4249 2.51168 23.5738 2.11694 22.8387 2.11694C17.2114 2.11694 14.5229 9.15172 13.68 12.7266C11.4933 13.4041 9.93989 13.8858 9.74147 13.9482C8.52092 14.331 8.48232 14.3695 8.32205 15.5196C8.20144 16.3903 5.00781 41.0881 5.00781 41.0881L29.8932 45.7507L43.3769 42.8338C43.3769 42.8338 38.6436 10.8319 38.6139 10.6126H38.6137ZM28.5074 8.13544L26.4017 8.78717C26.4025 8.63877 26.4032 8.49277 26.4032 8.33311C26.4032 6.94162 26.2101 5.82126 25.9002 4.93311C27.1449 5.08932 27.9739 6.50558 28.5074 8.13544ZM24.3561 5.20903C24.7022 6.07615 24.9272 7.32059 24.9272 8.99986C24.9272 9.08578 24.9264 9.16433 24.9257 9.24379C23.5563 9.66797 22.0682 10.1285 20.5768 10.5905C21.4142 7.35874 22.9838 5.79783 24.3561 5.20903V5.20903ZM22.6842 3.62634C22.9271 3.62634 23.1717 3.7088 23.4059 3.86997C21.6024 4.71862 19.6693 6.85601 18.853 11.1243L15.4154 12.189C16.3716 8.93332 18.6421 3.62634 22.684 3.62634H22.6842Z"
          fill="#95BF46"
        />
        <path
          d="M38.2328 10.2584C38.0743 10.2452 34.7206 9.99658 34.7206 9.99658C34.7206 9.99658 32.3914 7.6842 32.1357 7.42826C32.04 7.33303 31.911 7.28421 31.7761 7.26318L29.8945 45.7502L43.3771 42.8336C43.3771 42.8336 38.6436 10.8317 38.6138 10.6124C38.5836 10.3931 38.3915 10.2718 38.2328 10.2584"
          fill="#5E8E3E"
        />
        <path
          d="M25.3153 17.7087L23.6527 22.6543C23.6527 22.6543 22.196 21.8769 20.4104 21.8769C17.7926 21.8769 17.6609 23.5196 17.6609 23.9336C17.6609 26.1923 23.5489 27.0578 23.5489 32.3486C23.5489 36.5112 20.9087 39.1915 17.3489 39.1915C13.0771 39.1915 10.8926 36.5329 10.8926 36.5329L12.0364 32.7538C12.0364 32.7538 14.2819 34.6817 16.1767 34.6817C17.4149 34.6817 17.9185 33.7069 17.9185 32.9946C17.9185 30.0482 13.088 29.9168 13.088 25.0753C13.088 21.0004 16.0127 17.0571 21.9166 17.0571C24.1914 17.0571 25.3153 17.7087 25.3153 17.7087"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_273">
          <rect
            width="38.4559"
            height="43.8594"
            fill="white"
            transform="translate(5 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Shopify;
