import React from "react";
import styles from "../styles/logosStyles.module.css";

const Vscode = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_265)">
        <path
          d="M44.3281 6.85932L35.3187 2.52182C34.8099 2.27651 34.2374 2.19555 33.6806 2.29018C33.1237 2.38481 32.6101 2.65033 32.2109 3.04995L2.7187 29.9374C2.36088 30.2621 2.14668 30.7157 2.12324 31.1983C2.0998 31.6809 2.26903 32.153 2.5937 32.5109C2.63277 32.5531 2.67183 32.5937 2.71402 32.6312L5.12183 34.8187C5.43646 35.105 5.84145 35.2722 6.26646 35.291C6.69147 35.3098 7.10966 35.1792 7.44839 34.9218L42.9718 7.98432C43.2401 7.78071 43.56 7.65604 43.8953 7.62435C44.2306 7.59265 44.5681 7.65518 44.8699 7.8049C45.1716 7.95462 45.4255 8.18559 45.6031 8.47181C45.7807 8.75802 45.8748 9.08812 45.875 9.42495V9.32026C45.8749 8.80604 45.7297 8.30228 45.4561 7.86692C45.1824 7.43157 44.7914 7.08231 44.3281 6.85932Z"
          fill="#0065A9"
        />
        <path
          d="M44.3281 41.1405L35.3187 45.4795C34.8098 45.7242 34.2374 45.8047 33.6807 45.7101C33.124 45.6155 32.6105 45.3504 32.2109 44.9514L2.7187 18.0624C2.36088 17.7377 2.14668 17.2841 2.12324 16.8015C2.0998 16.3189 2.26903 15.8468 2.5937 15.4889C2.63277 15.4467 2.67183 15.4061 2.71402 15.3686L5.12183 13.1811C5.43678 12.8936 5.8427 12.7257 6.26876 12.7069C6.69482 12.688 7.11397 12.8194 7.45308 13.078L42.9718 40.0155C43.2401 40.2191 43.56 40.3438 43.8953 40.3755C44.2306 40.4072 44.5681 40.3446 44.8699 40.1949C45.1716 40.0452 45.4255 39.8142 45.6031 39.528C45.7807 39.2418 45.8748 38.9117 45.875 38.5749V38.6874C45.8733 39.2002 45.7273 39.7022 45.4538 40.136C45.1802 40.5699 44.7901 40.9179 44.3281 41.1405Z"
          fill="#007ACC"
        />
        <path
          d="M35.3182 45.4793C34.8092 45.7239 34.2369 45.8045 33.6802 45.7099C33.1235 45.6153 32.6099 45.3502 32.2104 44.9512C32.4347 45.1726 32.7195 45.3229 33.0289 45.3833C33.3384 45.4436 33.6587 45.4112 33.9499 45.2903C34.241 45.1693 34.4899 44.9651 34.6655 44.7033C34.8411 44.4415 34.9355 44.1336 34.9369 43.8184V4.18087C34.9372 3.86413 34.8434 3.55444 34.6675 3.29103C34.4916 3.02762 34.2415 2.82234 33.9489 2.7012C33.6562 2.58005 33.3342 2.5485 33.0236 2.61052C32.713 2.67255 32.4278 2.82536 32.2041 3.04962C32.6034 2.65015 33.1169 2.38461 33.6736 2.28972C34.2304 2.19482 34.8029 2.27527 35.3119 2.51993L44.3197 6.85118C44.7845 7.07305 45.1771 7.42184 45.4522 7.85728C45.7273 8.29272 45.8737 8.79706 45.8744 9.31212V38.6871C45.8745 39.2018 45.7293 39.706 45.4553 40.1417C45.1813 40.5773 44.7899 40.9267 44.326 41.1496L35.3182 45.4793Z"
          fill="#1F9CF0"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_265">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Vscode;
