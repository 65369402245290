import React from "react";
import styles from "../styles/logosStyles.module.css";

const LyftCabs = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_24_5)">
        <path
          d="M2.00391 8.9692H8.68039V26.9018C8.68039 29.7343 9.98234 31.4333 11.0067 32.156C9.92219 33.1202 6.59555 33.9641 4.1257 31.9154C2.66648 30.7105 2.00391 28.7219 2.00391 26.8416V8.9692ZM43.7506 23.8656V21.9853H45.7873V15.381H43.5461C42.6902 11.4158 39.159 8.43896 34.9291 8.43896C30.0599 8.43896 26.1197 12.3801 26.1197 17.2484V32.6742C27.5059 32.8667 29.1567 32.6501 30.567 31.4814C32.0253 30.2765 32.6879 28.2879 32.6879 26.4076V25.8413H36.0266V19.237H32.6879V17.2484H32.6999C32.6999 16.0075 33.7002 15.0072 34.9412 15.0072C36.1821 15.0072 37.1824 16.0075 37.1824 17.2484V23.8647C37.1824 28.734 41.1355 32.6742 46.0039 32.6742V26.094C44.7509 26.1069 43.7506 25.1065 43.7506 23.8656ZM18.4541 15.369V25.0704C18.4541 25.6247 17.996 26.0708 17.4297 26.0708C16.8634 26.0708 16.4053 25.6247 16.4053 25.0704V15.369H9.80102V26.7815C9.80102 28.8302 10.4997 31.4333 13.6699 32.2772C16.8402 33.1211 18.6835 31.3731 18.6835 31.3731C18.5151 32.5298 17.4305 33.3737 15.6826 33.5542C14.3566 33.6865 12.6696 33.2526 11.8137 32.8916V38.9416C13.9707 39.5801 16.2489 39.7855 18.4661 39.3515C22.5155 38.5678 25.0584 35.1819 25.0584 30.6865V15.381H18.4541V15.369Z"
          fill="#EA0B8C"
        />
      </g>
      <defs>
        <clipPath id="clip0_24_5">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LyftCabs;
