import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Youtube = () => {
    return (
        <svg
            //  width="48" height="48"
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.8841 13.4736C44.3822 11.5961 42.9034 10.1173 41.0259 9.61542C37.6231 8.70337 23.9767 8.70337 23.9767 8.70337C23.9767 8.70337 10.3303 8.70337 6.92745 9.61542C5.05 10.1173 3.57113 11.5961 3.06928 13.4736C2.15723 16.8765 2.15723 23.977 2.15723 23.977C2.15723 23.977 2.15723 31.0775 3.06928 34.4804C3.57113 36.3578 5.05 37.8367 6.92745 38.3386C10.3303 39.2506 23.9767 39.2506 23.9767 39.2506C23.9767 39.2506 37.6231 39.2506 41.0259 38.3386C42.9034 37.8367 44.3822 36.3578 44.8841 34.4804C45.7961 31.0775 45.7961 23.977 45.7961 23.977C45.7961 23.977 45.7961 16.8765 44.8841 13.4736Z" fill="#FF0000" />
            <path d="M19.6152 30.5248L30.9517 23.9789L19.6152 17.4331V30.5248Z" fill="white" />
        </svg>


    )
}

export default Youtube