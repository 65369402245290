import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Heroku = () => {
    return (
        <svg
            // width="48" height="48" 
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.2312 1C40.2446 1 41.881 2.61576 41.9135 4.6219L41.914 4.68281V38.2373C41.914 40.2507 40.2977 41.8871 38.2921 41.9196L38.2312 41.9201H8.76874C6.75535 41.9201 5.11896 40.3038 5.08643 38.2982L5.08594 38.2373V4.68281C5.08594 2.66891 6.7022 1.03301 8.70785 1.00049L8.76874 1H38.2312ZM38.2312 3.046H8.76874C7.88055 3.046 7.15494 3.75775 7.13248 4.64064L7.13194 4.68281V38.2373C7.13194 39.1255 7.84368 39.8511 8.72657 39.8735L8.76874 39.8741H38.2312C39.1199 39.8741 39.845 39.1623 39.8675 38.2794L39.868 38.2373V4.68281C39.868 3.78052 39.134 3.046 38.2312 3.046ZM14.293 27.5981L18.8965 31.6901L14.293 35.7821V27.5981ZM18.385 7.13801V18.7547C20.4279 18.0898 23.2836 17.368 26.0575 17.368C28.5868 17.368 30.1009 18.3624 30.9254 19.1966C32.6674 20.9589 32.7089 23.1989 32.7066 23.4976L32.7065 23.506L32.707 35.7821H28.615V23.5623C28.595 22.6073 28.1347 21.46 26.0575 21.46C21.9385 21.46 17.3233 23.5109 17.1862 23.5723L17.1834 23.5736L14.293 24.883V7.13801H18.385ZM32.707 7.13801C32.4308 9.46227 31.4886 11.6904 29.638 13.7875H25.546C27.1541 11.6776 28.1638 9.45716 28.615 7.13801H32.707Z" fill="#430098" />
        </svg>

    )
}

export default Heroku