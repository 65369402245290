import React from "react";
import styles from "../styles/logosStyles.module.css";

const Discord = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_320)">
        <path
          d="M38.4251 8.57482C35.6416 7.27251 32.6654 6.32604 29.5539 5.78711C29.1717 6.478 28.7253 7.40725 28.4175 8.14647C25.1098 7.64904 21.8326 7.64904 18.5857 8.14647C18.278 7.40725 17.8214 6.478 17.4359 5.78711C14.3209 6.32604 11.3413 7.27598 8.55785 8.58172C2.94357 17.0656 1.42163 25.3388 2.1826 33.4945C5.90628 36.2753 9.51498 37.9645 13.0628 39.0699C13.9388 37.8643 14.72 36.5827 15.393 35.2321C14.1112 34.745 12.8835 34.144 11.7235 33.4462C12.0313 33.2182 12.3323 32.9798 12.6231 32.7345C19.6984 36.0439 27.3859 36.0439 34.3767 32.7345C34.671 32.9798 34.972 33.2182 35.2763 33.4462C34.1129 34.1474 32.8819 34.7484 31.6 35.2356C32.2731 36.5827 33.051 37.8678 33.9303 39.0733C37.4815 37.9679 41.0936 36.2787 44.8172 33.4945C45.7101 24.04 43.2919 15.8428 38.4251 8.57482ZM16.3569 28.4788C14.233 28.4788 12.4912 26.496 12.4912 24.0814C12.4912 21.6668 14.1958 19.6806 16.3569 19.6806C18.5181 19.6806 20.2599 21.6633 20.2227 24.0814C20.226 26.496 18.5181 28.4788 16.3569 28.4788ZM30.6429 28.4788C28.519 28.4788 26.7772 26.496 26.7772 24.0814C26.7772 21.6668 28.4817 19.6806 30.6429 19.6806C32.804 19.6806 34.5458 21.6633 34.5087 24.0814C34.5087 26.496 32.804 28.4788 30.6429 28.4788Z"
          fill="#5865F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_320">
          <rect
            width="43"
            height="43"
            fill="white"
            transform="translate(2 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Discord;
