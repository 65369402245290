import React from "react";
import styles from "../styles/logosStyles.module.css";

const Ups = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_315)">
        <path
          d="M23.9998 44.4909C24.3652 44.3082 33.8678 40.1965 36.883 37.7295C39.9896 35.1711 41.6343 31.5163 41.6343 27.1305V6.57213L41.3602 6.48076C33.7764 2.36909 24.3652 2.6432 23.9084 2.6432C23.5429 2.6432 14.1317 2.36909 6.54798 6.48076L6.36523 6.57213V27.2219C6.36523 31.6077 8.0099 35.2625 11.1165 37.8209C14.1317 40.2879 23.6343 44.3995 23.9998 44.4909Z"
          fill="#341B14"
        />
        <path
          d="M24 44.9482C24 44.9482 13.9492 40.5624 10.8426 38.0954C7.5533 35.3543 6 31.6081 6 27.3137V5.933C13.9492 1.63859 24 2.00407 24 2.00407C24 2.00407 34.0508 1.63859 42 5.933V27.2223C42 31.5168 40.4467 35.263 37.1574 38.0041C34.0508 40.5624 24 44.9482 24 44.9482ZM7.46193 27.3137C7.46193 31.334 8.92386 34.6234 11.7563 36.9076C14.3147 39.0091 21.8985 42.3899 24 43.3036C26.1015 42.3899 33.7766 38.9178 36.2437 36.9076C39.0761 34.6234 40.5381 31.2427 40.5381 27.3137V6.29849C29.9391 5.29341 17.33 5.84163 7.46193 14.9787V27.3137Z"
          fill="#FFB406"
        />
        <path
          d="M34.1416 24.2981C35.5121 25.1204 36.0603 25.6686 36.1517 26.6737C36.1517 27.7702 35.4207 28.4098 34.2329 28.4098C33.2278 28.4098 32.04 27.8615 31.2177 27.1306V30.0544C32.2228 30.6026 33.4106 31.0595 34.6898 31.0595C37.7964 31.0595 39.2583 28.8666 39.2583 26.8565C39.3497 25.029 38.8015 23.5671 36.1517 22.0138C34.9639 21.2828 34.0502 20.826 34.0502 19.7295C34.0502 18.6331 35.0553 18.1762 35.8776 18.1762C36.974 18.1762 38.0705 18.8158 38.7101 19.4554V16.7143C38.1619 16.2575 36.974 15.6179 35.238 15.7092C33.1365 15.8006 30.9436 17.2625 30.9436 19.8209C31.035 21.557 31.5832 22.8361 34.1416 24.2981ZM23.3598 30.8768C23.6339 30.9681 24.0908 31.0595 24.8218 31.0595C28.3852 31.0595 30.3954 27.8615 30.3954 23.293C30.3954 18.6331 28.2938 15.8006 24.5476 15.8006C22.8116 15.8006 21.441 16.1661 20.2532 16.8971V37.5468H23.3598V30.8768ZM23.3598 18.4504C23.6339 18.359 24.0908 18.1762 24.4563 18.1762C26.2837 18.1762 27.0147 19.6382 27.0147 23.2016C27.0147 26.6737 26.1009 28.3184 24.2735 28.3184C23.8167 28.3184 23.4512 28.227 23.2685 28.1356V18.4504H23.3598ZM13.7659 31.0595C15.6847 31.0595 17.3294 30.6026 18.5172 29.7803V15.9834H15.3192V27.9529C14.9537 28.227 14.4969 28.3184 13.8573 28.3184C12.3954 28.3184 12.2126 26.9478 12.2126 26.1255V15.9834H9.01465V25.9427C9.01465 29.3235 10.6593 31.0595 13.7659 31.0595ZM35.0553 43.3945V45.496H35.4207V44.6737H35.5121L36.0603 45.496H36.5172C36.5172 45.496 35.969 44.6737 35.8776 44.5823C36.1517 44.491 36.3344 44.3082 36.3344 44.0341C36.3344 43.76 36.1517 43.3945 35.6035 43.3945H35.0553ZM35.6035 43.6686C35.8776 43.6686 35.969 43.8514 35.969 43.9427C35.969 44.1255 35.8776 44.3082 35.5121 44.3082H35.4207V43.6686H35.6035Z"
          fill="#FFB406"
        />
        <path
          d="M37.0661 44.3996C37.0661 45.1306 36.4265 45.7702 35.6955 45.7702C34.9645 45.7702 34.325 45.1306 34.325 44.3996C34.325 43.6687 34.9645 43.0291 35.6955 43.0291C36.5178 43.0291 37.0661 43.6687 37.0661 44.3996ZM35.6955 42.6636C34.7818 42.6636 33.9595 43.3945 33.9595 44.3996C33.9595 45.3133 34.6904 46.1357 35.6955 46.1357C36.6092 46.1357 37.4316 45.4047 37.4316 44.3996C37.4316 43.4859 36.6092 42.6636 35.6955 42.6636Z"
          fill="#FFB406"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_315">
          <rect
            width="36"
            height="44.1393"
            fill="white"
            transform="translate(6 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ups;
