import React from 'react'
import styles from "../styles/logosStyles.module.css"

const ICICIBank = () => {
    return (
        <svg
            // width="48" height="48"
            className={styles.svgStyles}

            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3338 10.4159C24.5069 2.34186 34.3952 0.234053 38.3597 5.76527C42.3242 11.2965 38.8477 22.4049 30.6747 30.532C22.5016 38.606 12.6133 40.7138 8.6488 35.1826C4.68428 29.6478 8.11124 18.5394 16.3338 10.4159Z" fill="#F99D27" />
            <path d="M21.7087 6.11044C20.8281 6.79653 19.898 7.52861 19.0174 8.40922C11.8239 15.4576 8.88504 25.1479 12.411 29.993C16.0325 34.8381 24.8916 33.0769 32.1841 25.9295C36.0991 22.1135 38.7409 17.6574 39.8691 13.5479C40.2121 10.5135 39.7701 7.82215 38.4014 5.76739C35.169 1.45983 28.4636 1.80287 21.7087 6.11044Z" fill="#B02A30" />
            <path d="M31.5566 5.8622C32.4867 6.79232 31.9492 8.89659 30.2834 10.5623C28.6177 12.1785 26.5134 12.7656 25.5833 11.8355C24.6037 10.9054 25.1907 8.8011 26.807 7.13537C28.5222 5.42012 30.6265 4.93207 31.5566 5.8622ZM25.7814 34.4909C21.9654 37.3767 18.0009 38.7984 14.1318 38.2114C15.7481 38.3104 17.0672 36.4997 18.1459 34.1019C19.221 31.6545 19.9071 29.3557 20.3987 27.202C21.1803 23.8245 21.2298 21.3772 20.8407 20.8396C20.2042 19.959 18.687 20.2031 17.0248 21.1827C16.2432 21.6248 15.1645 21.3312 16.4377 19.814C17.7109 18.2473 22.8 14.5268 24.6603 13.9398C26.6655 13.3527 28.9678 14.1343 28.1827 16.3376C27.5921 17.9503 20.5472 36.4501 25.7814 34.4909Z" fill="white" />
            <path d="M6 45.3287H7.48672L8.65203 41H7.16531L6 45.3287Z" fill="#005B75" />
            <path d="M13.6208 45.3287H15.1076L16.2729 41H14.7862L13.6208 45.3287Z" fill="#005B75" />
            <path d="M21.2446 45.3287H22.7313L23.8975 41H22.4108L21.2446 45.3287Z" fill="#005B75" />
            <path d="M12.241 42.4747H13.7277C13.9297 41.4993 13.2998 41 11.8371 41C10.1484 41 9.00634 41.8207 8.62563 43.2593C8.28103 44.5552 8.97024 45.3407 10.5524 45.3407C12.1577 45.3407 13.0978 44.8414 13.5017 43.7234H12.015C11.8131 44.2106 11.4203 44.4727 10.8849 44.4727C10.1716 44.4727 9.88634 44.0207 10.1003 43.188C10.3263 42.332 10.8738 41.8559 11.5991 41.8559C12.0631 41.868 12.2771 42.0708 12.241 42.4747Z" fill="#005B75" />
            <path d="M19.8658 42.4747H21.3525C21.5545 41.4993 20.9245 41 19.4619 41C17.7732 41 16.6311 41.8207 16.2504 43.2593C15.9058 44.5552 16.595 45.3407 18.1771 45.3407C19.7824 45.3407 20.7226 44.8414 21.1265 43.7234H19.6398C19.4378 44.2106 19.0451 44.4727 18.5097 44.4727C17.7964 44.4727 17.5111 44.0207 17.7251 43.188C17.9511 42.332 18.4985 41.8559 19.2238 41.8559C19.6879 41.868 19.9019 42.0708 19.8658 42.4747Z" fill="#005B75" />
            <path d="M25.0044 45.3411H27.2285C27.8584 45.3411 28.3345 45.2939 28.7865 44.9845C29.0838 44.7825 29.3099 44.4611 29.4053 44.1285C29.5599 43.5339 29.2867 43.13 28.6086 43.0346C29.2506 42.916 29.6553 42.5825 29.798 42.0592C29.9054 41.6664 29.8221 41.3811 29.5367 41.1912C29.2987 41.0365 28.918 41.0125 28.3946 41.0125H26.1826L25.0044 45.3411ZM26.9311 41.6664H27.8584C28.5485 41.6664 28.869 41.7498 28.7624 42.1898C28.6438 42.6178 28.2752 42.7372 27.549 42.7372H26.6449L26.9311 41.6664ZM26.1336 44.6519L26.4782 43.3912H27.4536C28.203 43.3912 28.5356 43.5218 28.3929 44.0332C28.2623 44.5092 27.9056 44.6519 27.2276 44.6519H26.1336Z" fill="#005B75" />
            <path d="M31.8543 45.3405H32.7464C32.7704 45.1386 32.8177 44.9125 32.889 44.6625L33.2104 43.449C33.4244 42.6524 33.0317 42.2356 32.021 42.2356C31.0456 42.2356 30.4157 42.5682 30.2249 43.1397H31.0809C31.1883 42.8543 31.4375 42.7237 31.8543 42.7237C32.3184 42.7237 32.4731 42.9257 32.3536 43.3657L32.3416 43.425H31.8655C30.5335 43.425 29.8202 43.663 29.6174 44.4356C29.4627 45.0303 29.8314 45.411 30.628 45.411C31.1393 45.411 31.5914 45.2443 31.948 44.9237L31.8543 45.3405ZM32.1989 43.9372L32.1869 43.9965C32.0322 44.555 31.5802 44.8885 31.0336 44.8885C30.6289 44.8885 30.463 44.7338 30.5343 44.4485C30.6417 44.0558 30.9864 43.9372 31.6403 43.9372H32.1989Z" fill="#005B75" />
            <path d="M33.5188 45.341H34.4229L34.8388 43.795C34.9935 43.2364 35.5048 42.8436 36.0402 42.8436C36.5636 42.8436 36.7535 43.153 36.5876 43.7709L36.1597 45.353H37.0397L37.539 43.4976C37.7529 42.713 37.349 42.261 36.4802 42.261C35.9096 42.261 35.4455 42.4509 35.0648 42.8677L35.1722 42.3323H34.2802C34.1848 42.8084 34.1255 43.1058 34.1015 43.2123L33.5188 45.341Z" fill="#005B75" />
            <path d="M37.8367 45.3407H38.7046L39.1446 43.7113L40.322 45.3407H41.5234L40.1914 43.6168L41.9995 42.32H40.8694L39.1928 43.5334L39.87 41H39.002L37.8367 45.3407Z" fill="#005B75" />
        </svg>


    )
}

export default ICICIBank