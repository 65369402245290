import React from "react";
import styles from "../styles/logosStyles.module.css";

const Amazon = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.7394 38.3308C21.5235 47 10.2185 39.7467 2.98181 35.3412C2.53401 35.0636 1.7729 35.4061 2.43326 36.1646C4.84417 39.0879 12.7452 46.1339 23.0584 46.1339C33.3786 46.1339 39.5182 40.5026 40.2862 39.5203C41.0491 38.5463 40.5102 38.009 39.7393 38.3308H39.7394ZM44.8553 35.5055C44.3661 34.8685 41.8808 34.7497 40.3167 34.9419C38.7502 35.1285 36.3988 36.0859 36.6032 36.6607C36.7081 36.8761 36.9223 36.7795 37.9984 36.6827C39.0775 36.5751 42.1005 36.1936 42.7304 37.017C43.3632 37.8461 41.7662 41.7959 41.4746 42.4329C41.1927 43.0699 41.5822 43.2341 42.1116 42.8099C42.6336 42.3858 43.5787 41.2875 44.213 39.7332C44.8429 38.1705 45.227 35.9905 44.8552 35.5055H44.8553Z"
        fill="#FF9900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4918 20.6412C27.4918 22.9159 27.5492 24.8129 26.3995 26.8329C25.4715 28.4754 24.0016 29.4855 22.3592 29.4855C20.1173 29.4855 18.8116 27.7773 18.8116 25.2564C18.8116 20.2798 23.2706 19.3766 27.4918 19.3766V20.6412ZM33.3797 34.8726C32.9937 35.2174 32.4353 35.2422 32.0001 35.0121C30.0621 33.4027 29.7171 32.6554 28.6496 31.1197C25.447 34.3881 23.1804 35.3653 19.0252 35.3653C14.1144 35.3653 10.2876 32.335 10.2876 26.2664C10.2876 21.5282 12.8581 18.3008 16.5122 16.7242C19.6821 15.328 24.1084 15.0817 27.4918 14.6958V13.9403C27.4918 12.5524 27.5984 10.91 26.7855 9.71115C26.071 8.63535 24.7078 8.19187 23.5088 8.19187C21.2834 8.19187 19.2961 9.33328 18.8116 11.6983C18.7129 12.224 18.3271 12.7414 17.8016 12.766L12.1352 12.1584C11.659 12.0514 11.1335 11.6656 11.2649 10.9346C12.5706 4.06955 18.7704 2 24.3217 2C27.163 2 30.8748 2.75557 33.1167 4.90717C35.9581 7.55955 35.6871 11.0989 35.6871 14.9503V24.0492C35.6871 26.7838 36.8203 27.9826 37.8878 29.4609C38.2655 29.9864 38.3477 30.6189 37.8714 31.0129C36.6806 32.0065 34.5619 33.8543 33.3959 34.8891L33.3795 34.8726"
        fill="black"
      />
      <path
        d="M39.7394 38.3308C21.5235 47 10.2185 39.7467 2.98181 35.3412C2.53401 35.0636 1.7729 35.4061 2.43326 36.1646C4.84417 39.0879 12.7452 46.1339 23.0584 46.1339C33.3786 46.1339 39.5182 40.5026 40.2862 39.5203C41.0491 38.5463 40.5102 38.009 39.7393 38.3308H39.7394ZM44.8553 35.5055C44.3661 34.8685 41.8808 34.7497 40.3167 34.9419C38.7502 35.1285 36.3988 36.0859 36.6032 36.6607C36.7081 36.8761 36.9223 36.7795 37.9984 36.6827C39.0775 36.5751 42.1005 36.1936 42.7304 37.017C43.3632 37.8461 41.7662 41.7959 41.4746 42.4329C41.1927 43.0699 41.5822 43.2341 42.1116 42.8099C42.6336 42.3858 43.5787 41.2875 44.213 39.7332C44.8429 38.1705 45.227 35.9905 44.8552 35.5055H44.8553Z"
        fill="#FF9900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4918 20.6412C27.4918 22.9159 27.5492 24.8129 26.3995 26.8329C25.4715 28.4754 24.0016 29.4855 22.3592 29.4855C20.1173 29.4855 18.8116 27.7773 18.8116 25.2564C18.8116 20.2798 23.2706 19.3766 27.4918 19.3766V20.6412ZM33.3797 34.8726C32.9937 35.2174 32.4353 35.2422 32.0001 35.0121C30.0621 33.4027 29.7171 32.6554 28.6496 31.1197C25.447 34.3881 23.1804 35.3653 19.0252 35.3653C14.1144 35.3653 10.2876 32.335 10.2876 26.2664C10.2876 21.5282 12.8581 18.3008 16.5122 16.7242C19.6821 15.328 24.1084 15.0817 27.4918 14.6958V13.9403C27.4918 12.5524 27.5984 10.91 26.7855 9.71115C26.071 8.63535 24.7078 8.19187 23.5088 8.19187C21.2834 8.19187 19.2961 9.33328 18.8116 11.6983C18.7129 12.224 18.3271 12.7414 17.8016 12.766L12.1352 12.1584C11.659 12.0514 11.1335 11.6656 11.2649 10.9346C12.5706 4.06955 18.7704 2 24.3217 2C27.163 2 30.8748 2.75557 33.1167 4.90717C35.9581 7.55955 35.6871 11.0989 35.6871 14.9503V24.0492C35.6871 26.7838 36.8203 27.9826 37.8878 29.4609C38.2655 29.9864 38.3477 30.6189 37.8714 31.0129C36.6806 32.0065 34.5619 33.8543 33.3959 34.8891L33.3795 34.8726"
        fill="black"
      />
    </svg>
  );
};

export default Amazon;
