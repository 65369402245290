import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Freelancer = () => {
    return (
        <svg
            //  width="48" height="48"
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.23522 39C8.23723 39.3881 8.2131 39.7741 8.16283 40.1582C7.7627 40.1722 7.26204 40.0275 7.27612 40.7151C7.89139 40.7011 8.05627 40.7151 8.05627 40.7151C8.05627 40.7151 8.02812 41.9316 7.11124 41.8854C6.78148 43.7453 6.46781 45.3759 6.46781 45.3759H5L5.80026 40.1742C5.80026 40.1742 5.98524 39.1166 6.94435 39.004L8.23522 39ZM7.5918 45.38L8.3639 40.6327H9.70906L9.81362 40.9906C9.81362 40.9906 10.1514 40.5181 11.1668 40.6327C10.9235 41.7909 10.8813 41.9638 10.8813 41.9638C10.8813 41.9638 9.76536 41.8492 9.6789 42.2071C9.59244 42.565 9.15009 45.382 9.15009 45.382L7.5918 45.38ZM19.5052 41.4892C19.2056 40.9464 18.6607 40.4497 17.3739 40.6126C16.087 40.7754 15.1641 42.0422 15.2284 43.7613C15.3591 45.191 16.3745 45.4443 16.7304 45.4926C19.1473 45.7781 19.1775 44.5194 19.1915 44.0469C18.6909 44.0469 18.4556 44.0569 18.2465 44.0609C18.1862 44.258 17.207 44.1615 17.207 44.1615C17.207 44.1615 16.835 44.1333 16.8068 43.4758H19.5112C19.5092 43.4758 19.8068 42.0341 19.5052 41.4892ZM18.1178 42.6474C17.8464 42.6756 16.9315 42.6474 16.9315 42.6474C17.2894 41.2319 18.2747 41.8472 18.1178 42.6474ZM38.4983 41.4892C38.1987 40.9464 37.6538 40.4497 36.367 40.6126C35.0801 40.7754 34.1572 42.0422 34.2215 43.7613C34.3522 45.191 35.3676 45.4443 35.7235 45.4926C38.1404 45.7781 38.1706 44.5194 38.1846 44.0469C37.684 44.0469 37.4487 44.0569 37.2396 44.0609C37.1793 44.258 36.2001 44.1615 36.2001 44.1615C36.2001 44.1615 35.8281 44.1333 35.7999 43.4758H38.5003C38.4983 43.4758 38.7919 42.0341 38.4983 41.4892ZM37.1109 42.6474C36.8395 42.6756 35.9246 42.6474 35.9246 42.6474C36.2765 41.2319 37.2637 41.8472 37.1049 42.6474H37.1109Z" fill="black" />
            <path d="M15.0915 41.4891C14.7919 40.9463 14.247 40.4496 12.9601 40.6125C11.6733 40.7753 10.7503 42.0421 10.8147 43.7612C10.9454 45.1908 11.9608 45.4442 12.3167 45.4925C14.7335 45.778 14.7637 44.5193 14.7778 44.0468C14.2771 44.0468 14.0419 44.0568 13.8328 44.0608C13.7724 44.2579 12.7932 44.1614 12.7932 44.1614C12.7932 44.1614 12.4212 44.1332 12.3931 43.4757H15.0935C15.0915 43.4757 15.3931 42.034 15.0915 41.4891ZM13.7021 42.6473C13.4306 42.6755 12.5157 42.6473 12.5157 42.6473C12.8797 41.2318 13.8609 41.847 13.7021 42.6473ZM20.5203 39.1145L19.5331 45.3778H21.1075L22.1651 39.1145H20.5203ZM25.7984 45.3798L26.5987 40.6145H28.0002V40.9784C28.0002 40.9784 28.4868 40.4496 29.7736 40.6145C31.0605 40.7774 30.417 43.0635 30.417 43.0635L30.0732 45.3839H28.529C28.529 45.3839 28.8869 43.0434 28.9573 42.4221C28.8849 41.7204 28.0565 41.7505 27.8554 42.2512C27.7549 42.8665 27.2984 45.3839 27.2984 45.3839L25.7984 45.3798ZM32.7635 41.9757H34.1791L34.3097 41.0468C34.3097 41.0468 34.4807 40.6185 33.8815 40.6105C33.2823 40.6024 32.3795 40.6105 32.3795 40.6105C32.3795 40.6105 30.6201 40.8598 30.7206 44.3081C30.8131 44.9234 31.2816 45.416 31.8909 45.5367C32.0216 45.5648 32.9304 45.5367 32.9304 45.5367C32.9304 45.5367 33.9458 45.406 33.8031 44.1352C32.7454 44.1634 32.3011 44.2076 32.2427 43.906C32.1824 43.6064 32.3634 42.0903 32.7635 41.9757ZM38.8559 42.2029L40.7058 40.6085L40 45.3798H38.4377L38.8559 42.2029Z" fill="black" />
            <path d="M41.0882 40.6084L40.8208 42.3175L43.7001 40.5823L41.0882 40.6084Z" fill="#29B2FE" />
            <path d="M24.8841 40.6125C23.6978 40.6025 23.4244 40.6226 22.982 40.6125C22.5377 40.6025 22.2381 41.262 22.2662 41.9617H24.0859C24.0859 41.9617 24.2709 41.9336 24.287 42.0924C24.295 42.2452 24.295 42.398 24.287 42.5508H22.9679C22.9679 42.5508 21.108 42.7217 21.6952 44.9677C21.8098 45.3115 22.3808 45.5508 22.6965 45.5387C23.0122 45.5267 23.7984 45.2391 23.927 45.1245L23.9411 45.396L25.3426 45.3819C25.3426 45.3819 25.8151 42.4503 25.8714 42.207C25.9277 41.9637 26.0282 41.0247 24.8841 40.6125ZM23.6576 44.3404C23.1047 44.3404 23.0283 43.8055 23.4686 43.52C23.9069 43.329 24.1744 43.4436 24.1744 43.4436C24.1744 43.4436 24.1945 44.3605 23.6576 44.3404Z" fill="black" />
            <path d="M20.4914 7.90556L2 6.87706L14.0512 19.8006L20.4914 7.90556ZM11.2403 6.52705L19.7699 7.13553L9.19949 3L11.2403 6.52705ZM21.9184 7.13553L26.4632 3L29.5271 7.394L21.9184 7.13553ZM21.4068 7.95402L14.7135 20.3122L20.793 27.0001L27.6317 20.6191L29.5217 8.36327L21.4068 7.95402ZM15.0743 22.2507L11.8542 35.6858L20.2276 27.7163L15.0743 22.2507ZM27.4324 3.20462L30.701 7.394L45.87 3.20462H27.4324Z" fill="#29B2FE" />
        </svg>

    )
}

export default Freelancer