import React from "react";
import styles from "./styles/iconStyles.module.css";
import { useSelector, useDispatch } from "react-redux";

const theme = "dark";

const DashboardIcon = () => {
  const isDarkMode = useSelector((state) => state.ui.darkMode);

  return (
    <>
      {isDarkMode === true ? (
        <svg
          className={styles.iconsStyles}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.59668C11.124 2.59668 10.248 2.8835 9.52142 3.45715L4.02142 7.79925C3.06058 8.55782 2.5 9.7146 2.5 10.9388V17.5004C2.5 19.7095 4.29086 21.5004 6.5 21.5004H8C8.55228 21.5004 9 21.0527 9 20.5004V17.5004C9 16.3958 9.89543 15.5004 11 15.5004H12V2.59668Z"
            fill="white"
          />
          <path
            d="M9.52142 3.45715L9.05669 2.86849V2.86849L9.52142 3.45715ZM12 2.59668H12.75V1.84668L12 1.84668V2.59668ZM4.02142 7.79925L4.48616 8.38792H4.48616L4.02142 7.79925ZM12 15.5004V16.2504H12.75V15.5004H12ZM9.98616 4.04581C10.5766 3.57966 11.2878 3.34668 12 3.34668V1.84668C10.9603 1.84668 9.91948 2.18734 9.05669 2.86849L9.98616 4.04581ZM4.48616 8.38792L9.98616 4.04581L9.05669 2.86849L3.55669 7.21059L4.48616 8.38792ZM3.25 10.9388C3.25 9.94413 3.70547 9.00425 4.48616 8.38792L3.55669 7.21059C2.41569 8.11138 1.75 9.48506 1.75 10.9388H3.25ZM3.25 17.5004V10.9388H1.75V17.5004H3.25ZM6.5 20.7504C4.70507 20.7504 3.25 19.2953 3.25 17.5004H1.75C1.75 20.1237 3.87665 22.2504 6.5 22.2504V20.7504ZM8 20.7504H6.5V22.2504H8V20.7504ZM8.25 20.5004C8.25 20.6384 8.13807 20.7504 8 20.7504V22.2504C8.9665 22.2504 9.75 21.4669 9.75 20.5004H8.25ZM8.25 17.5004V20.5004H9.75V17.5004H8.25ZM11 14.7504C9.48122 14.7504 8.25 15.9816 8.25 17.5004H9.75C9.75 16.81 10.3096 16.2504 11 16.2504V14.7504ZM12 14.7504H11V16.2504H12V14.7504ZM12.75 15.5004V2.59668H11.25V15.5004H12.75Z"
            fill="white"
          />
          <g opacity="0.3">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2.59668C12.876 2.59668 13.752 2.8835 14.4786 3.45715L19.9786 7.79925C20.9394 8.55782 21.5 9.7146 21.5 10.9388V17.5004C21.5 19.7095 19.7091 21.5004 17.5 21.5004H16C15.4477 21.5004 15 21.0527 15 20.5004V17.5004C15 16.3958 14.1046 15.5004 13 15.5004H12V2.59668Z"
              fill="white"
            />
            <path
              d="M14.4786 3.45715L14.9433 2.86849V2.86849L14.4786 3.45715ZM12 2.59668H11.25V1.84668L12 1.84668V2.59668ZM19.9786 7.79925L19.5138 8.38792H19.5138L19.9786 7.79925ZM12 15.5004V16.2504H11.25V15.5004H12ZM14.0138 4.04581C13.4234 3.57966 12.7122 3.34668 12 3.34668V1.84668C13.0397 1.84668 14.0805 2.18734 14.9433 2.86849L14.0138 4.04581ZM19.5138 8.38792L14.0138 4.04581L14.9433 2.86849L20.4433 7.21059L19.5138 8.38792ZM20.75 10.9388C20.75 9.94413 20.2945 9.00425 19.5138 8.38792L20.4433 7.21059C21.5843 8.11138 22.25 9.48506 22.25 10.9388H20.75ZM20.75 17.5004V10.9388H22.25V17.5004H20.75ZM17.5 20.7504C19.2949 20.7504 20.75 19.2953 20.75 17.5004H22.25C22.25 20.1237 20.1234 22.2504 17.5 22.2504V20.7504ZM16 20.7504H17.5V22.2504H16V20.7504ZM15.75 20.5004C15.75 20.6384 15.8619 20.7504 16 20.7504V22.2504C15.0335 22.2504 14.25 21.4669 14.25 20.5004H15.75ZM15.75 17.5004V20.5004H14.25V17.5004H15.75ZM13 14.7504C14.5188 14.7504 15.75 15.9816 15.75 17.5004H14.25C14.25 16.81 13.6904 16.2504 13 16.2504V14.7504ZM12 14.7504H13V16.2504H12V14.7504ZM11.25 15.5004V2.59668H12.75V15.5004H11.25Z"
              fill="white"
            />
          </g>
        </svg>
      ) : (
        <svg
          //   width="24" height="24"
          className={styles.iconsStyles}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.59619C11.124 2.59619 10.248 2.88301 9.52142 3.45666L4.02142 7.79877C3.06058 8.55733 2.5 9.71411 2.5 10.9383V17.4999C2.5 19.709 4.29086 21.4999 6.5 21.4999H8C8.55228 21.4999 9 21.0522 9 20.4999V17.4999C9 16.3953 9.89543 15.4999 11 15.4999H12V2.59619Z"
            fill="#2B3F6C"
          />
          <path
            d="M9.52142 3.45666L9.05669 2.868V2.868L9.52142 3.45666ZM12 2.59619H12.75V1.84619L12 1.84619V2.59619ZM4.02142 7.79877L4.48616 8.38743H4.48616L4.02142 7.79877ZM12 15.4999V16.2499H12.75V15.4999H12ZM9.98616 4.04532C10.5766 3.57918 11.2878 3.34619 12 3.34619V1.84619C10.9603 1.84619 9.91948 2.18685 9.05669 2.868L9.98616 4.04532ZM4.48616 8.38743L9.98616 4.04532L9.05669 2.868L3.55669 7.2101L4.48616 8.38743ZM3.25 10.9383C3.25 9.94365 3.70547 9.00376 4.48616 8.38743L3.55669 7.2101C2.41569 8.11089 1.75 9.48458 1.75 10.9383H3.25ZM3.25 17.4999V10.9383H1.75V17.4999H3.25ZM6.5 20.7499C4.70507 20.7499 3.25 19.2948 3.25 17.4999H1.75C1.75 20.1232 3.87665 22.2499 6.5 22.2499V20.7499ZM8 20.7499H6.5V22.2499H8V20.7499ZM8.25 20.4999C8.25 20.638 8.13807 20.7499 8 20.7499V22.2499C8.9665 22.2499 9.75 21.4664 9.75 20.4999H8.25ZM8.25 17.4999V20.4999H9.75V17.4999H8.25ZM11 14.7499C9.48122 14.7499 8.25 15.9811 8.25 17.4999H9.75C9.75 16.8095 10.3096 16.2499 11 16.2499V14.7499ZM12 14.7499H11V16.2499H12V14.7499ZM12.75 15.4999V2.59619H11.25V15.4999H12.75Z"
            fill="#2B3F6C"
          />
          <g opacity="0.3">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2.59619C12.876 2.59619 13.752 2.88301 14.4786 3.45666L19.9786 7.79877C20.9394 8.55733 21.5 9.71411 21.5 10.9383V17.4999C21.5 19.709 19.7091 21.4999 17.5 21.4999H16C15.4477 21.4999 15 21.0522 15 20.4999V17.4999C15 16.3953 14.1046 15.4999 13 15.4999H12V2.59619Z"
              fill="#2B3F6C"
            />
            <path
              d="M14.4786 3.45666L14.9433 2.868V2.868L14.4786 3.45666ZM12 2.59619H11.25V1.84619L12 1.84619V2.59619ZM19.9786 7.79877L19.5138 8.38743H19.5138L19.9786 7.79877ZM12 15.4999V16.2499H11.25V15.4999H12ZM14.0138 4.04532C13.4234 3.57918 12.7122 3.34619 12 3.34619V1.84619C13.0397 1.84619 14.0805 2.18685 14.9433 2.868L14.0138 4.04532ZM19.5138 8.38743L14.0138 4.04532L14.9433 2.868L20.4433 7.2101L19.5138 8.38743ZM20.75 10.9383C20.75 9.94365 20.2945 9.00376 19.5138 8.38743L20.4433 7.2101C21.5843 8.11089 22.25 9.48458 22.25 10.9383H20.75ZM20.75 17.4999V10.9383H22.25V17.4999H20.75ZM17.5 20.7499C19.2949 20.7499 20.75 19.2948 20.75 17.4999H22.25C22.25 20.1232 20.1234 22.2499 17.5 22.2499V20.7499ZM16 20.7499H17.5V22.2499H16V20.7499ZM15.75 20.4999C15.75 20.638 15.8619 20.7499 16 20.7499V22.2499C15.0335 22.2499 14.25 21.4664 14.25 20.4999H15.75ZM15.75 17.4999V20.4999H14.25V17.4999H15.75ZM13 14.7499C14.5188 14.7499 15.75 15.9811 15.75 17.4999H14.25C14.25 16.8095 13.6904 16.2499 13 16.2499V14.7499ZM12 14.7499H13V16.2499H12V14.7499ZM11.25 15.4999V2.59619H12.75V15.4999H11.25Z"
              fill="#2B3F6C"
            />
          </g>
        </svg>
      )}
    </>
  );
};

export default DashboardIcon;
