import React from "react";
import styles from "../styles/logosStyles.module.css";
const Paypal = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_25_19)">
        <path
          d="M37.1076 5.31938C35.0709 2.99907 31.3842 2.0022 26.6748 2.0022H13.0022C12.0397 2.0022 11.2232 2.70688 11.0686 3.6522L5.3709 39.7631C5.25918 40.4764 5.80918 41.1209 6.53106 41.1209H14.9787L17.1014 27.6717L17.0326 28.0928C17.1873 27.1389 17.9951 26.4428 18.9576 26.4428H22.9709C30.8514 26.4428 37.0217 23.2459 38.8264 13.9819C38.8779 13.7069 38.9295 13.4405 38.9639 13.1827C38.7404 13.0624 38.7404 13.0624 38.9639 13.1827C39.4967 9.76235 38.9553 7.43345 37.1076 5.31938Z"
          fill="#27346A"
        />
        <path
          d="M20.324 11.9452C20.5475 11.842 20.7967 11.7819 21.0631 11.7819H31.7795C33.0514 11.7819 34.2373 11.8678 35.3115 12.0397C35.6209 12.0913 35.9217 12.1428 36.2139 12.2116C36.5061 12.2803 36.7897 12.3491 37.0647 12.4264C37.2022 12.4694 37.3397 12.5038 37.4686 12.5553C38.0014 12.7358 38.4998 12.942 38.9467 13.1827C39.4795 9.76235 38.9467 7.43345 37.0904 5.31938C35.0709 2.99907 31.3842 2.0022 26.6748 2.0022H13.0022C12.0397 2.0022 11.2232 2.70688 11.0686 3.6522L5.3709 39.7631C5.25918 40.4764 5.80918 41.1209 6.53106 41.1209H14.9787L19.3787 13.2256C19.4647 12.6499 19.8342 12.1858 20.324 11.9452Z"
          fill="#27346A"
        />
        <path
          d="M38.8184 13.9821C37.0137 23.2375 30.8434 26.443 22.963 26.443H18.9497C17.9872 26.443 17.1708 27.1477 17.0247 28.093L14.3864 44.8165C14.2919 45.4438 14.7731 46.0024 15.4005 46.0024H22.5161C23.3583 46.0024 24.0716 45.3922 24.2091 44.5586L24.2778 44.1977L25.6184 35.6985L25.7044 35.2258C25.8333 34.3922 26.5552 33.7821 27.3973 33.7821H28.463C35.3551 33.7821 40.752 30.9805 42.3333 22.8852C42.995 19.4993 42.6512 16.6719 40.9067 14.6868C40.3825 14.0852 39.7208 13.5868 38.9559 13.1829C38.9216 13.4407 38.8786 13.7071 38.8184 13.9821Z"
          fill="#2790C3"
        />
        <path
          d="M37.0756 12.4258C36.8006 12.3484 36.517 12.2711 36.2248 12.2109C35.9326 12.1422 35.6318 12.0906 35.3225 12.0391C34.2396 11.8672 33.0537 11.7812 31.7818 11.7812H21.0654C20.799 11.7812 20.5498 11.8414 20.3264 11.9531C19.8279 12.1937 19.467 12.6578 19.3725 13.2336L17.0951 27.6797L17.0264 28.1008C17.1725 27.1469 17.9889 26.4508 18.9514 26.4508H22.9732C30.8537 26.4508 37.024 23.2539 38.8287 13.9898C38.8803 13.7148 38.9232 13.4484 38.9662 13.1906C38.5107 12.95 38.0123 12.7437 37.4881 12.5633C37.3506 12.5117 37.2131 12.4688 37.0756 12.4258Z"
          fill="#1F264F"
        />
      </g>
      <defs>
        <clipPath id="clip0_25_19">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Paypal;
