import React from "react";
import styles from "../styles/logosStyles.module.css";

const Bing = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_237_350)">
        <path
          d="M23.1961 17.2598C23.1895 17.3126 23.1895 17.3726 23.1895 17.4315C23.1895 17.6603 23.2351 17.8804 23.3176 18.0828L23.3914 18.2346L23.6829 18.9806L25.1974 22.8442L26.5183 26.2181C26.8951 26.8992 27.4986 27.4339 28.2265 27.7189L28.4526 27.802C28.4614 27.8047 28.4762 27.8047 28.4883 27.808L32.1087 29.053V29.0557L33.4929 29.531L33.5875 29.5629C33.5908 29.5629 33.5963 29.5662 33.5996 29.5662C33.8724 29.635 34.1398 29.7274 34.3951 29.8402C34.9854 30.0949 35.5206 30.458 35.9778 30.9009C36.1621 31.0769 36.3315 31.2667 36.4883 31.4691C36.6218 31.6391 36.7438 31.8177 36.8536 32.0039C37.3366 32.806 37.6139 33.7489 37.6139 34.7529C37.6139 34.9322 37.6051 35.1055 37.5869 35.2815C37.5809 35.3591 37.5715 35.4339 37.56 35.5082V35.5143C37.5479 35.5929 37.533 35.6743 37.5182 35.7541C37.5028 35.8317 37.4885 35.9087 37.4708 35.9857C37.4681 35.9945 37.4648 36.0039 37.4615 36.0127C37.445 36.0908 37.4241 36.1678 37.4026 36.2454C37.3834 36.3185 37.3592 36.3939 37.3322 36.4649C37.3085 36.5425 37.2807 36.6189 37.2491 36.6937C37.2222 36.7708 37.1903 36.8489 37.1545 36.9226C37.0566 37.1487 36.94 37.366 36.809 37.5734C36.6403 37.8458 36.4475 38.1027 36.2331 38.3408C37.9319 36.5144 39.0613 34.156 39.3424 31.5462C39.3897 31.1182 39.4139 30.6841 39.4139 30.2473C39.4139 29.9684 39.4018 29.6922 39.3837 29.415C39.1696 26.2853 37.7448 23.4862 35.5701 21.4887C34.9705 20.9336 34.3153 20.4396 33.6106 20.0204L32.2793 19.3399L25.533 15.8779C25.3256 15.789 25.1023 15.7433 24.8767 15.7437C24.0031 15.7437 23.2819 16.4099 23.1961 17.2598Z"
          fill="#7F7F7F"
        />
        <path
          d="M23.1961 17.2598C23.1895 17.3126 23.1895 17.3726 23.1895 17.4315C23.1895 17.6603 23.2351 17.8804 23.3176 18.0828L23.3914 18.2346L23.6829 18.9806L25.1974 22.8442L26.5183 26.2181C26.8951 26.8992 27.4986 27.4339 28.2265 27.7189L28.4526 27.802C28.4614 27.8047 28.4762 27.8047 28.4883 27.808L32.1087 29.053V29.0557L33.4929 29.531L33.5875 29.5629C33.5908 29.5629 33.5963 29.5662 33.5996 29.5662C33.8724 29.635 34.1398 29.7274 34.3951 29.8402C34.9854 30.0949 35.5206 30.458 35.9778 30.9009C36.1621 31.0769 36.3315 31.2667 36.4883 31.4691C36.6218 31.6391 36.7438 31.8177 36.8536 32.0039C37.3366 32.806 37.6139 33.7489 37.6139 34.7529C37.6139 34.9322 37.6051 35.1055 37.5869 35.2815C37.5809 35.3591 37.5715 35.4339 37.56 35.5082V35.5143C37.5479 35.5929 37.533 35.6743 37.5182 35.7541C37.5028 35.8317 37.4885 35.9087 37.4708 35.9857C37.4681 35.9945 37.4648 36.0039 37.4615 36.0127C37.445 36.0908 37.4241 36.1678 37.4026 36.2454C37.3834 36.3185 37.3592 36.3939 37.3322 36.4649C37.3085 36.5425 37.2807 36.6189 37.2491 36.6937C37.2222 36.7708 37.1903 36.8489 37.1545 36.9226C37.0566 37.1487 36.94 37.366 36.809 37.5734C36.6403 37.8458 36.4475 38.1027 36.2331 38.3408C37.9319 36.5144 39.0613 34.156 39.3424 31.5462C39.3897 31.1182 39.4139 30.6841 39.4139 30.2473C39.4139 29.9684 39.4018 29.6922 39.3837 29.415C39.1696 26.2853 37.7448 23.4862 35.5701 21.4887C34.9705 20.9336 34.3153 20.4396 33.6106 20.0204L32.2793 19.3399L25.533 15.8779C25.3256 15.789 25.1023 15.7433 24.8767 15.7437C24.0031 15.7437 23.2819 16.4099 23.1961 17.2598Z"
          fill="url(#paint0_linear_237_350)"
        />
        <path
          d="M11.1836 2C10.2236 2.01705 9.45288 2.80264 9.45288 3.76317V34.5188C9.45673 34.7333 9.46883 34.9451 9.48424 35.1602C9.49799 35.2736 9.51285 35.3913 9.53375 35.5057C9.9722 37.9742 12.1199 39.8473 14.7132 39.8473C15.6226 39.8473 16.4753 39.6157 17.2213 39.213C17.2257 39.2097 17.2334 39.2053 17.2372 39.2053L17.5057 39.0431L18.5939 38.4027L19.978 37.5814L19.9813 10.2547C19.9813 8.43873 19.0725 6.83785 17.6812 5.88667C17.6482 5.86687 17.6163 5.84596 17.5871 5.82231L12.2013 2.30037C11.9285 2.11993 11.5995 2.0066 11.2469 2H11.1836Z"
          fill="#7F7F7F"
        />
        <path
          d="M11.1836 2C10.2236 2.01705 9.45288 2.80264 9.45288 3.76317V34.5188C9.45673 34.7333 9.46883 34.9451 9.48424 35.1602C9.49799 35.2736 9.51285 35.3913 9.53375 35.5057C9.9722 37.9742 12.1199 39.8473 14.7132 39.8473C15.6226 39.8473 16.4753 39.6157 17.2213 39.213C17.2257 39.2097 17.2334 39.2053 17.2372 39.2053L17.5057 39.0431L18.5939 38.4027L19.978 37.5814L19.9813 10.2547C19.9813 8.43873 19.0725 6.83785 17.6812 5.88667C17.6482 5.86687 17.6163 5.84596 17.5871 5.82231L12.2013 2.30037C11.9285 2.11993 11.5995 2.0066 11.2469 2H11.1836Z"
          fill="url(#paint1_linear_237_350)"
        />
        <path
          d="M32.5389 30.0975L20.156 37.4374L19.9778 37.5446V37.5837L18.5937 38.4034L17.5061 39.0454L17.2392 39.2066L17.2211 39.2154C16.4768 39.6197 15.6235 39.8486 14.7147 39.8486C12.1219 39.8486 9.97147 37.9765 9.53467 35.507C9.74207 37.3136 10.3577 38.9953 11.2863 40.4603C13.1985 43.4789 16.4322 45.5787 20.1731 45.9435H22.4605C24.4839 45.7322 26.1734 44.9219 27.9492 43.8277L30.68 42.1575C31.9101 41.3691 35.2489 39.4299 36.2325 38.3418C36.4465 38.1036 36.6396 37.8483 36.8085 37.5749C36.9394 37.3669 37.0561 37.1496 37.154 36.9235C37.187 36.8465 37.2183 36.7717 37.2486 36.6952C37.2772 36.6193 37.3042 36.5428 37.3317 36.4653C37.3828 36.3167 37.4235 36.1677 37.4626 36.0142C37.4824 35.9267 37.5006 35.8403 37.5187 35.7545C37.5781 35.4305 37.6106 35.0977 37.6106 34.7527C37.6106 33.7487 37.3333 32.8058 36.8531 32.0048C36.743 31.8178 36.622 31.639 36.4878 31.4701C36.331 31.2665 36.1615 31.0779 35.9772 30.9018C35.5201 30.459 34.9865 30.0959 34.3945 29.8406C34.1393 29.7278 33.873 29.6349 33.599 29.5672C33.5957 29.5672 33.5902 29.5639 33.5869 29.5639L33.4923 29.532L32.5389 30.0975Z"
          fill="#7F7F7F"
        />
        <path
          d="M32.5389 30.0975L20.156 37.4374L19.9778 37.5446V37.5837L18.5937 38.4034L17.5061 39.0454L17.2392 39.2066L17.2211 39.2154C16.4768 39.6197 15.6235 39.8486 14.7147 39.8486C12.1219 39.8486 9.97147 37.9765 9.53467 35.507C9.74207 37.3136 10.3577 38.9953 11.2863 40.4603C13.1985 43.4789 16.4322 45.5787 20.1731 45.9435H22.4605C24.4839 45.7322 26.1734 44.9219 27.9492 43.8277L30.68 42.1575C31.9101 41.3691 35.2489 39.4299 36.2325 38.3418C36.4465 38.1036 36.6396 37.8483 36.8085 37.5749C36.9394 37.3669 37.0561 37.1496 37.154 36.9235C37.187 36.8465 37.2183 36.7717 37.2486 36.6952C37.2772 36.6193 37.3042 36.5428 37.3317 36.4653C37.3828 36.3167 37.4235 36.1677 37.4626 36.0142C37.4824 35.9267 37.5006 35.8403 37.5187 35.7545C37.5781 35.4305 37.6106 35.0977 37.6106 34.7527C37.6106 33.7487 37.3333 32.8058 36.8531 32.0048C36.743 31.8178 36.622 31.639 36.4878 31.4701C36.331 31.2665 36.1615 31.0779 35.9772 30.9018C35.5201 30.459 34.9865 30.0959 34.3945 29.8406C34.1393 29.7278 33.873 29.6349 33.599 29.5672C33.5957 29.5672 33.5902 29.5639 33.5869 29.5639L33.4923 29.532L32.5389 30.0975Z"
          fill="url(#paint2_linear_237_350)"
        />
        <path
          opacity="0.15"
          d="M37.6106 34.7527C37.6106 35.0977 37.5781 35.431 37.5182 35.7551C37.5006 35.8409 37.4824 35.9267 37.4621 36.0136C37.4235 36.1677 37.3828 36.3167 37.3317 36.4658C37.3047 36.5428 37.2772 36.6199 37.2486 36.6947C37.2189 36.7717 37.187 36.8465 37.1534 36.9235C37.0553 37.1489 36.9402 37.3665 36.809 37.5743C36.6404 37.8468 36.4474 38.1035 36.2325 38.3412C35.2489 39.4299 31.9101 41.3691 30.68 42.1575L27.9492 43.8277C25.9473 45.0611 24.0559 45.9341 21.6706 45.9941C21.5578 45.9974 21.4472 46.0001 21.3377 46.0001C21.1837 46.0001 21.0318 45.9974 20.8795 45.9913C16.8404 45.8373 13.3179 43.667 11.2863 40.4603C10.3356 38.9628 9.73677 37.2692 9.53467 35.507C9.97147 37.9765 12.1219 39.8486 14.7147 39.8486C15.6235 39.8486 16.4768 39.6192 17.2211 39.2154L17.2392 39.206L17.5061 39.0454L18.5937 38.4034L19.9778 37.5837V37.5446L20.156 37.4374L32.5389 30.097L33.4923 29.532L33.5869 29.5639C33.5897 29.5639 33.5957 29.5672 33.599 29.5672C33.873 29.6349 34.1398 29.7278 34.3945 29.8406C34.9865 30.0959 35.5195 30.459 35.9772 30.9018C36.1615 31.0781 36.3321 31.2681 36.4878 31.4701C36.622 31.639 36.743 31.8178 36.8531 32.0048C37.3333 32.8058 37.6106 33.7487 37.6106 34.7527Z"
          fill="#7F7F7F"
        />
        <path
          opacity="0.15"
          d="M37.6106 34.7527C37.6106 35.0977 37.5781 35.431 37.5182 35.7551C37.5006 35.8409 37.4824 35.9267 37.4621 36.0136C37.4235 36.1677 37.3828 36.3167 37.3317 36.4658C37.3047 36.5428 37.2772 36.6199 37.2486 36.6947C37.2189 36.7717 37.187 36.8465 37.1534 36.9235C37.0553 37.1489 36.9402 37.3665 36.809 37.5743C36.6404 37.8468 36.4474 38.1035 36.2325 38.3412C35.2489 39.4299 31.9101 41.3691 30.68 42.1575L27.9492 43.8277C25.9473 45.0611 24.0559 45.9341 21.6706 45.9941C21.5578 45.9974 21.4472 46.0001 21.3377 46.0001C21.1837 46.0001 21.0318 45.9974 20.8795 45.9913C16.8404 45.8373 13.3179 43.667 11.2863 40.4603C10.3356 38.9628 9.73677 37.2692 9.53467 35.507C9.97147 37.9765 12.1219 39.8486 14.7147 39.8486C15.6235 39.8486 16.4768 39.6192 17.2211 39.2154L17.2392 39.206L17.5061 39.0454L18.5937 38.4034L19.9778 37.5837V37.5446L20.156 37.4374L32.5389 30.097L33.4923 29.532L33.5869 29.5639C33.5897 29.5639 33.5957 29.5672 33.599 29.5672C33.873 29.6349 34.1398 29.7278 34.3945 29.8406C34.9865 30.0959 35.5195 30.459 35.9772 30.9018C36.1615 31.0781 36.3321 31.2681 36.4878 31.4701C36.622 31.639 36.743 31.8178 36.8531 32.0048C37.3333 32.8058 37.6106 33.7487 37.6106 34.7527Z"
          fill="url(#paint3_linear_237_350)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_237_350"
          x1="25.1992"
          y1="17.6278"
          x2="39.2647"
          y2="33.3034"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37BDFF" />
          <stop offset="0.25" stopColor="#26C6F4" />
          <stop offset="0.5" stopColor="#15D0E9" />
          <stop offset="0.75" stopColor="#3BD6DF" />
          <stop offset="1" stopColor="#62DCD4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_237_350"
          x1="15.4393"
          y1="39.1822"
          x2="14.987"
          y2="4.39687"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B48EF" />
          <stop offset="0.5" stopColor="#2080F1" />
          <stop offset="1" stopColor="#26B8F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_237_350"
          x1="23.5725"
          y1="37.7377"
          x2="57.6151"
          y2="37.7377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#39D2FF" />
          <stop offset="0.5" stopColor="#248FFA" />
          <stop offset="1" stopColor="#104CF5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_237_350"
          x1="23.5725"
          y1="37.7377"
          x2="57.6151"
          y2="37.7377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_237_350">
          <rect
            width="33.2288"
            height="44"
            fill="white"
            transform="translate(7 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Bing;
