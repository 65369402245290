import React from "react";
import styles from "../styles/logosStyles.module.css";

const Twitter = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_288)">
        <path
          d="M46 10.354C44.3819 11.0709 42.6436 11.5554 40.8163 11.7744C42.6805 10.6582 44.1106 8.887 44.7849 6.77866C43.0409 7.8135 41.1089 8.56533 39.053 8.96839C37.4058 7.21668 35.061 6.11987 32.4643 6.11987C27.4803 6.11987 23.438 10.1621 23.438 15.1501C23.4383 15.8413 23.5163 16.5304 23.6706 17.2042C16.1654 16.8262 9.51677 13.2316 5.06369 7.77086C4.28865 9.10795 3.84286 10.6582 3.84286 12.3092C3.84286 15.4406 5.43775 18.206 7.85801 19.8241C6.42457 19.7784 5.02276 19.3911 3.76924 18.6943V18.8106C3.76924 23.1841 6.87945 26.8331 11.0109 27.6625C10.2356 27.8717 9.4361 27.9772 8.63314 27.9764C8.06294 27.9769 7.49397 27.9237 6.93374 27.8175C8.08285 31.4024 11.4159 34.0146 15.3671 34.0844C12.2762 36.5066 8.3832 37.9503 4.15486 37.9503C3.42625 37.9503 2.7073 37.9077 2 37.8243C5.99582 40.3882 10.7396 41.8802 15.836 41.8802C32.443 41.8802 41.5217 28.1256 41.5217 16.1965C41.5217 15.8089 41.512 15.4174 41.4945 15.0299C43.258 13.7548 44.7888 12.1678 45.996 10.3598L46 10.354Z"
          fill="#1DA1F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_288">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter;
