import React from "react";
import styles from "../styles/logosStyles.module.css";

const Playstation = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_237_335)">
        <path
          d="M8.213 29.7888C6.96596 30.6202 7.38164 32.1998 10.042 32.948C12.7855 33.8625 15.7784 34.1119 18.6882 33.6131C18.8545 33.6131 19.0207 33.53 19.1039 33.53V30.7033L16.2772 31.6178C15.1965 31.9504 14.1157 32.0335 13.0349 31.7841C12.2035 31.5347 12.3698 31.0359 13.3675 30.6202L19.1039 28.6249V25.5489L11.1228 28.2924C10.1251 28.6249 9.1275 29.1237 8.213 29.7888ZM27.5007 17.3184V25.3826C30.9092 27.0453 33.5696 25.3826 33.5696 21.0595C33.5696 16.6533 31.99 14.658 27.4175 13.0784C25.0066 12.247 22.5125 11.4988 20.0184 11V35.0264L25.8379 36.7723V16.5701C25.8379 15.6556 25.8379 14.9905 26.503 15.24C27.4175 15.4894 27.5007 16.4039 27.5007 17.3184ZM38.3084 27.8767C35.8974 27.0453 33.3202 26.7128 30.8261 26.9622C29.4959 27.0453 28.2489 27.3779 27.085 27.7936L26.8356 27.8767V31.119L32.2394 29.1237C33.3202 28.7912 34.401 28.7081 35.4817 28.9575C36.3131 29.2069 36.1468 29.7057 35.1492 30.1214L26.8356 33.1974V36.3566L38.3084 32.1167C39.1397 31.7841 39.888 31.3684 40.5531 30.7033C41.135 29.872 40.8856 28.7081 38.3084 27.8767Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_237_335">
          <rect
            width="44.1004"
            height="25.7723"
            fill="white"
            transform="translate(2 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Playstation;
