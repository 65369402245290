import React from "react";
import styles from "../styles/logosStyles.module.css";

const EA = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_127_4)">
        <path
          d="M24.0002 2C11.8695 2 2.00049 11.8691 2.00049 24.0001C2.00049 36.131 11.8695 46 24.0002 46C36.131 46 46 36.131 46 24.0001C46 11.8691 36.131 2 24.0002 2ZM24.0002 43.1506C13.4406 43.1506 4.84992 34.5597 4.84992 24.0001C4.84992 13.4403 13.4406 4.84944 24.0002 4.84944C34.5596 4.84944 43.1505 13.4403 43.1505 24.0001C43.1505 34.5596 34.5597 43.1506 24.0002 43.1506ZM31.4744 17.5831H31.648V16.7421H31.9609V16.5936H31.1622V16.7421H31.4744L31.4744 17.5831ZM32.2379 16.8185H32.2411L32.5155 17.5831H32.6569L32.9313 16.8185H32.9352V17.5831H33.0982V16.5937H32.8586L32.5933 17.3676H32.5888L32.3171 16.5937H32.0736V17.5831H32.238L32.2379 16.8185ZM27.2415 16.5928H16.4187L14.7767 19.2317H25.5869L27.2415 16.5928ZM29.4533 16.618L22.8371 27.1531H15.4981L17.1913 24.512H21.5899L23.2701 21.872H13.1013L11.4215 24.512H13.8305L10.4881 29.7852H24.4368L29.5888 21.6602L31.4673 24.5119H29.7734L28.1693 27.1531H33.1852L34.9269 29.7852H38.1266L29.4533 16.618Z"
          fill="#295BA7"
        />
      </g>
      <defs>
        <clipPath id="clip0_127_4">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EA;
