import React from "react";
import styles from "../styles/logosStyles.module.css";

const Github = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_314)">
        <path
          d="M24.0157 3C11.8583 3 2 12.8566 2 25.0157C2 34.7429 8.30815 42.9953 17.0557 45.9064C18.1559 46.1102 18.56 45.4289 18.56 44.8473C18.56 44.3224 18.5394 42.588 18.5301 40.7484C12.4052 42.0802 11.1128 38.1508 11.1128 38.1508C10.1113 35.6061 8.66833 34.9296 8.66833 34.9296C6.67094 33.5631 8.8189 33.5911 8.8189 33.5911C11.0297 33.7465 12.1938 35.8599 12.1938 35.8599C14.1574 39.2257 17.3441 38.2525 18.6004 37.6902C18.7979 36.2671 19.3686 35.296 19.9981 34.7462C15.1082 34.1894 9.96764 32.3016 9.96764 23.8659C9.96764 21.4624 10.8277 19.4983 12.2361 17.9566C12.0075 17.402 11.254 15.1629 12.4494 12.1304C12.4494 12.1304 14.2981 11.5386 18.5052 14.3871C20.2612 13.8991 22.1447 13.6547 24.0157 13.6464C25.8867 13.6547 27.7715 13.8991 29.531 14.3871C33.7331 11.5386 35.5792 12.1304 35.5792 12.1304C36.7775 15.1629 36.0237 17.402 35.7951 17.9566C37.2066 19.4983 38.0608 21.4622 38.0608 23.8659C38.0608 32.3217 32.9105 34.1837 28.0082 34.7286C28.7978 35.4119 29.5014 36.7517 29.5014 38.8056C29.5014 41.7513 29.4759 44.1221 29.4759 44.8473C29.4759 45.4332 29.8721 46.1197 30.9883 45.9035C39.731 42.9891 46.0312 34.7397 46.0312 25.0157C46.0312 12.8566 36.1743 3 24.0157 3Z"
          fill="#161614"
        />
        <path
          d="M10.2459 34.3619C10.1976 34.4712 10.0252 34.504 9.86864 34.429C9.70892 34.3572 9.61914 34.208 9.67094 34.0982C9.71842 33.9856 9.89074 33.9542 10.0501 34.0298C10.2102 34.1015 10.3013 34.252 10.2459 34.3619ZM11.3289 35.3281C11.2239 35.4255 11.0186 35.3802 10.8792 35.2264C10.7352 35.0729 10.7083 34.8678 10.8148 34.7688C10.9231 34.6716 11.1222 34.717 11.2665 34.8707C11.4105 35.0259 11.4385 35.2298 11.3287 35.3283M12.0718 36.5644C11.9368 36.6581 11.7162 36.5702 11.5799 36.3744C11.4451 36.1788 11.4451 35.944 11.5829 35.8499C11.7196 35.7558 11.9368 35.8404 12.075 36.0346C12.2096 36.2337 12.2096 36.4685 12.0717 36.5645M13.3282 37.9963C13.2075 38.1292 12.9505 38.0937 12.7623 37.912C12.57 37.7345 12.5163 37.4826 12.6373 37.3495C12.7594 37.2162 13.0179 37.2537 13.2075 37.4337C13.3986 37.6109 13.457 37.8647 13.3283 37.9963M14.9519 38.4797C14.8989 38.6521 14.6513 38.7305 14.402 38.6572C14.153 38.5818 13.99 38.3798 14.0402 38.2056C14.092 38.032 14.3407 37.9504 14.5919 38.0287C14.8405 38.1039 15.0039 38.3043 14.9521 38.4797M16.8001 38.6847C16.8063 38.8663 16.5948 39.0169 16.3331 39.0202C16.0697 39.0259 15.8568 38.8789 15.8541 38.7004C15.8541 38.517 16.0608 38.3679 16.3239 38.3635C16.5857 38.3584 16.8001 38.5043 16.8001 38.6847ZM18.6155 38.6151C18.6469 38.7923 18.465 38.9743 18.2051 39.0226C17.9495 39.0692 17.713 38.9599 17.6804 38.7843C17.6486 38.6027 17.834 38.4209 18.0891 38.3737C18.3494 38.3285 18.5824 38.435 18.6155 38.6151Z"
          fill="#161614"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_314">
          <rect
            width="44.2023"
            height="43"
            fill="white"
            transform="translate(2 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Github;
