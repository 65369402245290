import React from "react";
import styles from "../styles/logosStyles.module.css";

const Outlook = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_280)">
        <path
          d="M41.5827 4.75024H17.9437C17.4317 4.75024 16.9407 4.95364 16.5786 5.31567C16.2166 5.67771 16.0132 6.16874 16.0132 6.68074V8.87524L29.3369 13.0002L43.5132 8.87524V6.68074C43.5132 6.16874 43.3098 5.67771 42.9478 5.31567C42.5857 4.95364 42.0947 4.75024 41.5827 4.75024V4.75024Z"
          fill="#0364B8"
        />
        <path
          d="M45.7814 25.9321C45.9828 25.2991 46.1436 24.6538 46.2627 24.0002C46.2625 23.8397 46.2201 23.682 46.1397 23.543C46.0594 23.4039 45.9439 23.2885 45.8048 23.2082L45.7869 23.1972L45.7814 23.1945L30.8792 14.7052C30.8149 14.6635 30.7483 14.6254 30.6798 14.5911C30.3948 14.4496 30.0809 14.376 29.7627 14.376C29.4445 14.376 29.1306 14.4496 28.8456 14.5911C28.7771 14.6249 28.7105 14.6626 28.6462 14.7039L13.7439 23.1959L13.7384 23.1986L13.7219 23.2082C13.5826 23.2883 13.4669 23.4037 13.3862 23.5427C13.3056 23.6817 13.263 23.8395 13.2627 24.0002C13.3818 24.6538 13.5426 25.2991 13.7439 25.9321L29.5454 37.489L45.7814 25.9321Z"
          fill="#0A2767"
        />
        <path
          d="M35.2623 8.87524H25.6373L22.8584 13.0002L25.6373 17.1252L35.2623 25.3752H43.5123V17.1252L35.2623 8.87524Z"
          fill="#28A8EA"
        />
        <path
          d="M16.0132 8.87524H25.6382V17.1252H16.0132V8.87524Z"
          fill="#0078D4"
        />
        <path
          d="M35.2627 8.87524H43.5127V17.1252H35.2627V8.87524Z"
          fill="#50D9FF"
        />
        <path
          d="M35.2632 25.375L25.6382 17.125H16.0132V25.375L25.6382 33.625L40.5322 36.056L35.2632 25.375Z"
          fill="#0364B8"
        />
        <path
          d="M25.6387 17.125H35.2637V25.375H25.6387V17.125Z"
          fill="#0078D4"
        />
        <path
          d="M16.0132 25.3752H25.6382V33.6252H16.0132V25.3752Z"
          fill="#064A8C"
        />
        <path
          d="M35.2627 25.3752H43.5127V33.6252H35.2627V25.3752Z"
          fill="#0078D4"
        />
        <path
          opacity="0.5"
          d="M30.0245 36.675L13.8091 24.85L14.4897 23.6538C14.4897 23.6538 29.2641 32.0688 29.4896 32.1953C29.5818 32.2322 29.6807 32.2498 29.78 32.247C29.8794 32.2441 29.9771 32.2209 30.0671 32.1788L45.1 23.6125L45.782 24.8074L30.0245 36.675Z"
          fill="#0A2767"
        />
        <path
          d="M45.8052 24.7936L45.7859 24.8046L45.7818 24.8074L30.8796 33.2966C30.5836 33.4875 30.2436 33.5992 29.8921 33.6211C29.5407 33.643 29.1894 33.5744 28.8721 33.4217L34.0613 40.3806L45.4106 42.8515V42.857C45.6746 42.666 45.8895 42.4151 46.0377 42.125C46.1859 41.8348 46.2631 41.5136 46.2631 41.1877V24.0002C46.2631 24.161 46.2208 24.319 46.1405 24.4583C46.0601 24.5976 45.9445 24.7132 45.8052 24.7936V24.7936Z"
          fill="#1490DF"
        />
        <path
          opacity="0.05"
          d="M46.2631 41.1878V40.1731L32.5364 32.3521L30.8796 33.2967C30.5836 33.4875 30.2436 33.5992 29.8921 33.6211C29.5407 33.6431 29.1894 33.5744 28.8721 33.4218L34.0613 40.3807L45.4106 42.8516V42.8571C45.6746 42.6661 45.8895 42.4152 46.0377 42.125C46.1859 41.8348 46.2631 41.5136 46.2631 41.1878V41.1878Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M46.1943 41.7142L31.1477 33.1438L30.8796 33.295C30.5837 33.4861 30.2438 33.5981 29.8923 33.6202C29.5408 33.6424 29.1896 33.574 28.8721 33.4215L34.0613 40.3804L45.4106 42.8513V42.8568C45.7954 42.578 46.0723 42.1749 46.1943 41.7155V41.7142Z"
          fill="black"
        />
        <path
          d="M13.744 24.8115V24.7977H13.7302L13.689 24.7702C13.5575 24.6896 13.4492 24.5763 13.3745 24.4413C13.2998 24.3064 13.2613 24.1545 13.2627 24.0002V41.1877C13.2624 41.4587 13.3155 41.7271 13.419 41.9775C13.5225 42.2279 13.6744 42.4554 13.866 42.647C14.0576 42.8386 14.2851 42.9905 14.5355 43.094C14.7859 43.1975 15.0543 43.2506 15.3252 43.2502H44.2002C44.372 43.2486 44.5429 43.2255 44.709 43.1815C44.7951 43.1665 44.8786 43.1387 44.9565 43.099C44.9857 43.0961 45.0139 43.0867 45.039 43.0715C45.1515 43.0255 45.2578 42.9654 45.3552 42.8927C45.3827 42.879 45.3965 42.879 45.4102 42.8515L13.744 24.8115Z"
          fill="#28A8EA"
        />
        <path
          opacity="0.1"
          d="M27.0127 35.9176V13.4584C27.0112 12.9727 26.8177 12.5073 26.4743 12.1639C26.1308 11.8205 25.6655 11.6269 25.1798 11.6255H16.0539V21.8775L13.7439 23.1947L13.7371 23.1975L13.7206 23.2071C13.5813 23.2875 13.4657 23.4032 13.3853 23.5424C13.3049 23.6817 13.2626 23.8397 13.2627 24.0005V24.0074V24.0005V37.7505H25.1798C25.6655 37.749 26.1308 37.5555 26.4743 37.212C26.8177 36.8686 27.0112 36.4033 27.0127 35.9176V35.9176Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M25.6377 37.2926V14.8334C25.6362 14.3477 25.4427 13.8823 25.0993 13.5389C24.7558 13.1955 24.2905 13.0019 23.8048 13.0005H16.0539V21.8775L13.7439 23.1947L13.7371 23.1975L13.7206 23.2071C13.5813 23.2875 13.4657 23.4032 13.3853 23.5424C13.3049 23.6817 13.2626 23.8397 13.2627 24.0005V24.0074V24.0005V39.1255H23.8048C24.2905 39.124 24.7558 38.9305 25.0993 38.587C25.4427 38.2436 25.6362 37.7783 25.6377 37.2926V37.2926Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M25.6377 34.5426V14.8334C25.6362 14.3477 25.4427 13.8823 25.0993 13.5389C24.7558 13.1955 24.2905 13.0019 23.8048 13.0005H16.0539V21.8775L13.7439 23.1947L13.7371 23.1975L13.7206 23.2071C13.5813 23.2875 13.4657 23.4032 13.3853 23.5424C13.3049 23.6817 13.2626 23.8397 13.2627 24.0005V24.0074V24.0005V36.3755H23.8048C24.2905 36.374 24.7558 36.1805 25.0993 35.837C25.4427 35.4936 25.6362 35.0283 25.6377 34.5426V34.5426Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M24.2627 34.5426V14.8334C24.2612 14.3477 24.0677 13.8823 23.7243 13.5389C23.3808 13.1955 22.9155 13.0019 22.4298 13.0005H16.0539V21.8775L13.7439 23.1947L13.7371 23.1975L13.7206 23.2071C13.5813 23.2875 13.4657 23.4032 13.3853 23.5424C13.3049 23.6817 13.2626 23.8397 13.2627 24.0005V24.0074V24.0005V36.3755H22.4298C22.9155 36.374 23.3808 36.1805 23.7243 35.837C24.0677 35.4936 24.2612 35.0283 24.2627 34.5426V34.5426Z"
          fill="black"
        />
        <path
          d="M4.09606 13.0005H22.4303C22.9164 13.0005 23.3826 13.1936 23.7263 13.5373C24.0701 13.8811 24.2632 14.3473 24.2632 14.8334V33.1676C24.2632 33.6537 24.0701 34.1199 23.7263 34.4637C23.3826 34.8074 22.9164 35.0005 22.4303 35.0005H4.09606C3.60995 35.0005 3.14375 34.8074 2.80002 34.4637C2.45629 34.1199 2.26318 33.6537 2.26318 33.1676L2.26318 14.8334C2.26318 14.3473 2.45629 13.8811 2.80002 13.5373C3.14375 13.1936 3.60995 13.0005 4.09606 13.0005V13.0005Z"
          fill="#0078D4"
        />
        <path
          d="M7.58075 20.5187C8.06778 19.4807 8.85403 18.6121 9.8385 18.0244C10.9292 17.4001 12.171 17.0889 13.4273 17.1252C14.5904 17.0999 15.7382 17.3948 16.7451 17.9777C17.693 18.5422 18.4563 19.3701 18.9424 20.3605C19.4719 21.4522 19.7357 22.6537 19.7124 23.8668C19.7378 25.1339 19.4661 26.3894 18.919 27.5325C18.4224 28.5582 17.636 29.4158 16.6571 29.9993C15.6107 30.6007 14.4194 30.9036 13.2128 30.8752C12.024 30.9035 10.8503 30.6053 9.81925 30.013C8.86383 29.4475 8.09147 28.6188 7.5945 27.626C7.0614 26.5504 6.79404 25.3627 6.81488 24.1624C6.79182 22.9056 7.05366 21.6599 7.58075 20.5187V20.5187ZM9.987 26.3734C10.2465 27.0296 10.6865 27.5988 11.2561 28.0152C11.8366 28.4214 12.532 28.6311 13.2403 28.6133C13.9948 28.6426 14.7384 28.426 15.3591 27.9959C15.9224 27.5798 16.3513 27.0075 16.5925 26.35C16.863 25.6175 16.9963 24.8415 16.9858 24.0607C16.9941 23.2725 16.8686 22.4886 16.6145 21.7424C16.3903 21.0712 15.9768 20.479 15.4238 20.0374C14.8194 19.5857 14.0779 19.3561 13.3241 19.387C12.6004 19.3687 11.8895 19.58 11.2933 19.9907C10.7124 20.4077 10.2622 20.9814 9.99525 21.6448C9.40463 23.1655 9.4012 24.8517 9.98563 26.3748L9.987 26.3734Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_280">
          <rect
            width="45.8907"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Outlook;
