import React from "react";
import styles from "../styles/logosStyles.module.css";

const Quora = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_307)">
        <path
          d="M23.5 43C34.8218 43 44 33.8218 44 22.5C44 11.1782 34.8218 2 23.5 2C12.1782 2 3 11.1782 3 22.5C3 33.8218 12.1782 43 23.5 43Z"
          fill="#B0271E"
        />
        <path
          d="M29.1268 13.6951C28.6716 13.6728 27.8203 13.1572 27.3652 12.9751C24.8803 12.1468 22.1194 11.3185 19.7725 12.1468C13.2843 14.2176 16.1833 21.8101 15.7692 26.9179C15.7692 27.4585 15.5817 27.9147 15.4136 28.4621L29.1587 42.2089C36.0302 40.2395 41.423 34.7735 43.2907 27.8596L29.1268 13.6951Z"
          fill="#961411"
        />
        <path
          d="M32.9767 29.1814C32.9767 29.1814 32.8619 30.866 31.3304 30.866C30.1478 30.866 29.3401 29.9639 28.5837 28.7926C30.7319 26.9175 32.096 24.1173 32.096 20.9883C32.096 15.3427 27.6565 10.7661 22.1801 10.7661C16.7036 10.7661 12.2642 15.3428 12.2642 20.9883C12.2642 26.6338 16.7036 31.2106 22.1801 31.2106C23.174 31.2106 24.1333 31.0592 25.0386 30.7787C26.1839 32.5981 27.6378 34.2341 29.9138 34.2341C34.5462 34.2341 34.7377 29.1814 34.7377 29.1814H32.9767ZM22.1802 29.526C18.9873 29.526 16.399 25.7035 16.399 20.9883C16.399 16.2732 18.9873 12.4506 22.1802 12.4506C25.373 12.4506 27.9613 16.2732 27.9613 20.9883C27.9613 22.8633 27.5517 24.5967 26.8579 26.0052C26.0741 24.8862 25.1333 23.9334 23.7882 23.63C21.402 23.0919 19.3471 24.1661 18.6962 24.7021L19.3087 25.9655C19.3087 25.9655 19.928 25.6095 21.4635 25.9651C22.4309 26.1892 23.1828 27.5318 24.0444 29.0723C23.4596 29.3663 22.8324 29.526 22.1802 29.526Z"
          fill="white"
        />
        <path
          d="M32.9758 29.1814C32.9758 29.1814 32.8609 30.866 31.3294 30.866C30.1468 30.866 29.3391 29.9639 28.5827 28.7926C30.7309 26.9175 32.095 24.1173 32.095 20.9883C32.095 15.3427 27.6555 10.7661 22.1791 10.7661C22.1745 10.7661 22.1701 10.7664 22.1655 10.7665V12.4513C22.1701 12.4513 22.1745 12.4507 22.1791 12.4507C25.3719 12.4507 27.9602 16.2732 27.9602 20.9884C27.9602 22.8634 27.5506 24.5968 26.8568 26.0053C26.073 24.8863 25.1322 23.9335 23.7871 23.6301C23.2234 23.5029 22.6783 23.4667 22.1656 23.4912V26.3401C22.8202 26.8874 23.4014 27.9246 24.0434 29.0724C23.4585 29.3666 22.8315 29.5263 22.1792 29.5263C22.1747 29.5263 22.1703 29.5257 22.1657 29.5257V31.2105C22.1703 31.2105 22.1747 31.2109 22.1792 31.2109C23.1731 31.2109 24.1325 31.0594 25.0378 30.7789C26.183 32.5984 27.6369 34.2344 29.9129 34.2344C34.5454 34.2344 34.7368 29.1817 34.7368 29.1817H32.9758V29.1814Z"
          fill="#D1D1D1"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_307">
          <rect
            width="41"
            height="41"
            fill="white"
            transform="translate(3 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Quora;
