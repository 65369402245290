import React from "react";
import styles from "../styles/logosStyles.module.css";

const Alienware = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_237_334)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7024 6.80343C16.5169 3.98898 20.3276 2 23.8039 2C27.2801 2 31.0908 3.98898 33.9053 6.80343C36.2252 9.11619 37.8797 11.276 38.2142 14.5885C38.8866 18.723 38.7087 25.1881 38.2142 27.3408C37.8797 29.6606 36.058 32.4751 34.407 35.2895C31.0908 40.7548 26.6219 45.7219 23.8039 45.7219C20.9858 45.7219 16.6876 40.7405 13.3715 35.2895C11.5497 32.4715 9.89523 29.6606 9.3971 27.3408C8.89896 25.3518 8.89896 19.0789 9.23342 14.9194C9.728 11.4396 11.2188 9.28698 13.7024 6.80343ZM15.1968 25.3518C17.3317 26.5117 20.4913 30.4861 21.1531 33.1369C22.1529 36.4495 21.6548 37.1113 18.1749 35.2717C16.186 34.2968 13.0406 31.1515 12.3788 29.1625C10.7243 25.3518 10.8879 23.1991 15.1968 25.3518ZM32.5817 25.3518C30.2618 26.5117 27.1164 30.4861 26.4546 33.1369C25.6256 36.4495 25.9601 37.1113 29.4363 35.2717C31.4146 34.2968 34.5707 31.1515 35.3997 29.1625C36.8906 25.3518 36.7233 23.1991 32.5817 25.3518Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_237_334">
          <rect
            width="29.6532"
            height="43.7219"
            fill="white"
            transform="translate(9 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Alienware;
