import React from "react";
import styles from "../styles/logosStyles.module.css";

const Dell = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_125_28)">
        <path
          d="M23.318 2.06567H24.6819C31.3165 2.4398 36.0469 4.95907 39.5539 8.48972C43.0611 12.0204 45.6535 16.677 45.9999 23.3397V24.6597C45.6631 31.4289 43.0248 35.8966 39.5539 39.5317C35.9078 42.9846 31.4257 45.6007 24.6599 45.9335H23.318C16.6843 45.5587 11.953 43.0404 8.44596 39.5096C4.93892 35.9789 2.34643 31.3224 2 24.6597V23.3397C2.01726 23.3349 2.02571 23.3213 2.02201 23.2957C2.35373 16.6584 4.96036 11.9534 8.44605 8.46771C11.9478 4.9658 16.7273 2.42851 23.318 2.06567ZM19.006 5.21168C17.6988 5.5617 16.5265 6.00621 15.464 6.53164C12.2299 8.13087 9.56283 10.4189 7.69799 13.3516C5.87784 16.2143 4.30185 19.8968 4.53008 24.7256C4.67398 27.7699 5.36864 30.3307 6.46596 32.5136C8.63302 36.8237 11.9322 39.9995 16.4981 41.9297C18.7559 42.8842 21.5536 43.5537 24.704 43.4036C27.726 43.2597 30.3446 42.5805 32.5141 41.4897C36.766 39.3517 40.1169 36.0531 41.9742 31.5017C42.9135 29.1994 43.6238 26.5396 43.4702 23.3176C43.1796 17.2141 40.6968 13.0756 37.4203 9.91963C35.799 8.35806 33.8468 7.02974 31.5463 6.06968C29.3142 5.13816 26.4084 4.44749 23.2962 4.59566C21.7663 4.66851 20.323 4.85899 19.006 5.21168Z"
          fill="#0083CA"
        />
        <path
          d="M21.9764 18.3008C22.6734 18.7624 23.3864 19.2081 24.0444 19.7088C22.5579 20.6422 21.1005 21.6049 19.6224 22.5468C19.8469 22.7184 20.1021 22.8591 20.3264 23.0307C21.8168 22.0865 23.3077 21.1429 24.7925 20.1927C25.4584 20.6049 26.1139 21.0273 26.7285 21.4908C25.2408 22.4305 23.8029 23.4199 22.3285 24.3727C22.5469 24.5504 22.8147 24.6785 23.0543 24.8348C24.5783 23.902 26.0642 22.9313 27.5643 21.9749V18.8508H30.8644V25.0769C31.7956 25.1281 32.8225 25.0842 33.7905 25.0987V28.0248H27.5643V25.0547C25.7351 26.166 23.8605 27.4228 22.0204 28.5967C20.1041 27.3524 18.2573 26.0383 16.3444 24.7908C15.7902 26.5473 14.4741 27.8686 12.2963 28.0248C10.9381 28.1219 9.39474 27.9522 7.83032 28.0248V18.8508C9.78613 18.9804 11.9337 18.5892 13.4843 19.0708C14.8553 19.4966 15.8273 20.5905 16.2783 21.9528C18.1926 20.7504 20.0762 19.5175 21.9764 18.3008ZM10.9762 21.7988V25.0988C11.7623 25.1027 12.3602 25.1483 12.8242 24.7908C13.5145 24.2592 13.6535 22.9411 13.0442 22.2388C12.6375 21.7702 11.9509 21.6718 10.9981 21.7328C10.9727 21.7365 10.9743 21.7676 10.9762 21.7988ZM34.6483 18.8508H37.9483V25.0988H40.8523V28.0249H34.6261V18.9168C34.6244 18.8856 34.6226 18.8545 34.6483 18.8508Z"
          fill="#0083CA"
        />
      </g>
      <defs>
        <clipPath id="clip0_125_28">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Dell;
