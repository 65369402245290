import React from "react";
import styles from "../styles/logosStyles.module.css";

const GDrive = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_321)">
        <path
          d="M31.9423 30.679H15.9146L9.05127 42.6674C9.87693 43.0873 10.8146 43.3564 11.421 43.3564C17.7259 43.3564 30.5932 43.2631 36.9807 43.2631C37.6375 43.2631 38.2523 43.0392 38.8037 42.6884L31.9423 30.679Z"
          fill="#4185F3"
        />
        <path
          d="M9.05124 42.6679L15.9145 30.6794H2.00696C1.99996 31.5126 2.29617 32.2748 2.76032 33.1443C4.05339 35.5635 6.24117 39.4306 7.52632 41.4504C7.84306 41.9481 9.10068 42.6931 9.05124 42.6679Z"
          fill="#1767D1"
        />
        <path
          d="M31.9419 30.6794L38.8056 42.687C39.6952 42.1216 40.4201 41.226 40.8908 40.4013C41.9534 38.5414 43.7503 35.4445 44.9897 33.3477C45.5658 32.3727 45.7897 31.4971 45.7758 30.6785L31.9419 30.6794Z"
          fill="#E94235"
        />
        <path
          d="M15.9154 30.6801L23.9211 16.7623L16.7663 4.8335C15.9924 5.34242 15.3305 6.03328 15.0301 6.55946C11.907 12.0368 5.61561 23.2626 2.45196 28.8109C2.12779 29.3786 1.97258 30.0271 2.00461 30.6801H15.9154Z"
          fill="#30A753"
        />
        <path
          d="M31.9415 30.6797L23.9214 16.7619L30.8798 4.84668C31.6537 5.35607 32.5032 6.03293 32.8036 6.55959C35.9267 12.0365 42.218 23.2623 45.3831 28.8106C45.7096 29.3811 45.808 30.0267 45.7763 30.6797H31.9415Z"
          fill="#F9BC00"
        />
        <path
          d="M16.7656 4.83529L23.9214 16.7622L30.8798 4.84695C30.1615 4.42712 29.1441 4.17849 28.1589 4.14723C25.4179 4.0558 20.9742 4.01895 18.5826 4.12298C17.9929 4.14863 16.7731 4.83016 16.7656 4.83529Z"
          fill="#0F8038"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_321">
          <rect
            width="43.8083"
            height="39.3749"
            fill="white"
            transform="translate(2 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GDrive;
