import React from "react";
import styles from "../styles/logosStyles.module.css";

const AppleAppStore = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_260)">
        <path d="M2 2H46V46H2V2Z" fill="url(#paint0_linear_21_260)" />
        <path
          d="M16.309 34.0332L16.3131 34.0345L14.8286 36.6056C14.2868 37.5441 13.0866 37.8657 12.1481 37.3238C11.2096 36.782 10.888 35.5819 11.4299 34.6433L12.5234 32.7493L12.6284 32.5675C12.8158 32.2981 13.2783 31.8332 14.2035 31.9206C14.2035 31.9206 16.3806 32.1569 16.5381 33.2884C16.5381 33.2884 16.5596 33.6608 16.309 34.0332V34.0332ZM37.3636 27.4661H32.7345C32.4193 27.4451 32.2816 27.3325 32.2276 27.267L32.2241 27.2611L27.2688 18.6783L27.2624 18.6826L26.9652 18.2565C26.4782 17.5117 25.7048 19.4166 25.7048 19.4166C24.7814 21.5393 25.8358 23.9526 26.2035 24.682L33.0861 36.6029C33.6279 37.5415 34.828 37.863 35.7666 37.3212C36.705 36.7794 37.0266 35.5792 36.4848 34.6407L34.7637 31.6598C34.7304 31.5876 34.6724 31.3914 35.0253 31.3907H37.3636C38.4473 31.3907 39.3258 30.5121 39.3258 29.4284C39.3258 28.3447 38.4473 27.4661 37.3636 27.4661ZM28.3725 30.1338C28.3725 30.1338 28.6195 31.3906 27.6635 31.3906H26.7074V31.3907H10.5511C9.46741 31.3907 8.58887 30.5121 8.58887 29.4284C8.58887 28.3447 9.46741 27.4661 10.5511 27.4661H14.9505C15.6608 27.425 15.8292 27.015 15.8292 27.015L15.8331 27.017L21.5757 17.0703L21.574 17.0699C21.6787 16.8778 21.5916 16.6962 21.5764 16.6675L19.6799 13.3827C19.138 12.4441 19.4596 11.244 20.3981 10.7022C21.3366 10.1603 22.5367 10.4819 23.0786 11.4204L23.9581 12.9439L24.8362 11.4231C25.378 10.4846 26.5781 10.163 27.5166 10.7049C28.4552 11.2468 28.7767 12.4469 28.2349 13.3854L20.2438 27.2263C20.2088 27.3106 20.1981 27.4429 20.4581 27.4661H25.2347L25.2357 27.5127C25.2357 27.5127 27.9965 27.5557 28.3725 30.1338Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_21_260"
          x1="24"
          y1="46"
          x2="24"
          y2="2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D76F2" />
          <stop offset="1" stopColor="#1AC2FB" />
        </linearGradient>
        <clipPath id="clip0_21_260">
          <rect x="2" y="2" width="44" height="44" rx="9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppleAppStore;
