import React from "react";
import styles from "../styles/logosStyles.module.css";

const Bitcoin = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_241_386)">
        <path
          d="M46 24.0035C46 36.153 36.1495 46.0035 23.9947 46.0053C11.8488 46.0053 1.99824 36.153 2 24.0018C1.99648 11.8505 11.847 2 23.9982 2C36.1495 2 46 11.8523 46 24.0035Z"
          fill="#EAB300"
        />
        <path
          d="M32.6553 18.6136C32.3719 15.6669 29.8282 14.6794 26.614 14.3977V10.3086H24.1267V14.2886C23.4736 14.2886 22.8047 14.3009 22.1411 14.315V10.3086H19.6538L19.6521 14.3942C19.1134 14.4048 18.5836 14.4153 18.0678 14.4153V14.403L14.637 14.4012V17.0593C14.637 17.0593 16.4747 17.0241 16.4431 17.0575C17.4517 17.0575 17.7791 17.6419 17.8742 18.1471V22.8048C17.9446 22.8048 18.0344 22.8084 18.1365 22.8224H17.8742L17.8724 29.3478C17.8284 29.6647 17.6418 30.1699 16.9377 30.1716C16.9694 30.1998 15.1299 30.1716 15.1299 30.1716L14.6353 33.143H17.8742C18.4762 33.143 19.0694 33.1535 19.6503 33.157L19.6521 37.292H22.1376V33.2011C22.8188 33.2151 23.4789 33.2204 24.1249 33.2204L24.1232 37.292H26.6105V33.1659C30.7929 32.9265 33.7237 31.872 34.0864 27.9431C34.3803 24.7799 32.8929 23.3664 30.5183 22.796C31.9635 22.0638 32.8665 20.7699 32.6553 18.6136ZM29.1734 27.4537C29.1734 30.543 23.8838 30.1927 22.1957 30.1927V24.713C23.8838 24.7165 29.1734 24.2324 29.1734 27.4537ZM28.0152 19.7243C28.0152 22.5355 23.6004 22.2063 22.1957 22.2081V17.2406C23.6021 17.2406 28.0169 16.7935 28.0152 19.7243Z"
          fill="white"
        />
        <path
          d="M17.8779 22.6958H18.2476V22.9686H17.8779V22.6958Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_241_386">
          <rect
            width="44"
            height="44.007"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Bitcoin;
