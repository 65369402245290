import React from "react";
import styles from "../styles/logosStyles.module.css";

const Godaddy = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_237_369)">
        <path
          d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z"
          fill="#1BDBDB"
        />
        <path
          d="M31.9752 15.5732C29.6893 14.1466 26.6772 14.4861 24.0002 16.1704C21.319 14.4818 18.3112 14.1423 16.0252 15.5732C12.4115 17.829 11.9733 23.6427 15.0455 28.5583C17.31 32.1806 20.8506 34.3032 24.0002 34.2646C27.1498 34.3032 30.6904 32.1806 32.9549 28.5583C36.0272 23.6427 35.5889 17.829 31.9752 15.5732ZM16.7041 27.5228C16.0596 26.4872 15.5826 25.3958 15.2862 24.2786C15.0069 23.2259 14.9037 22.1947 14.9811 21.2193C15.1186 19.4017 15.8576 17.988 17.0608 17.2361C18.2639 16.4841 19.8623 16.4368 21.551 17.1114C21.8045 17.2146 22.058 17.3306 22.3072 17.4595C21.4049 18.2759 20.5756 19.2599 19.8709 20.3857C18.0061 23.372 17.4389 26.6892 18.0877 29.3404C17.5807 28.7904 17.1166 28.1802 16.7041 27.5228ZM32.7143 24.2743C32.4178 25.3958 31.9451 26.4872 31.2963 27.5185C30.8838 28.1802 30.4197 28.7861 29.9084 29.3361C30.4885 26.9642 30.0975 24.0552 28.6666 21.331C28.5678 21.1376 28.3358 21.0775 28.151 21.1892L23.7037 23.9693C23.5319 24.0767 23.4803 24.3001 23.5877 24.472L24.2408 25.5161C24.3483 25.688 24.5717 25.7396 24.7436 25.6322L27.6268 23.8318C27.7213 24.1068 27.8115 24.3861 27.8846 24.6697C28.1639 25.7224 28.267 26.7536 28.1897 27.729C28.0522 29.5466 27.3131 30.9603 26.11 31.7122C25.5084 32.0904 24.808 32.288 24.0475 32.3095H23.9529C23.1924 32.288 22.492 32.0904 21.8904 31.7122C20.6873 30.9603 19.9483 29.5466 19.8108 27.729C19.7377 26.7536 19.8408 25.7224 20.1158 24.6697C20.408 23.5482 20.885 22.4568 21.5338 21.4255C22.1783 20.39 22.9518 19.4833 23.8283 18.7271C24.6533 18.0138 25.5342 17.4724 26.4451 17.1114C28.1381 16.4368 29.7322 16.4841 30.9354 17.2361C32.1385 17.988 32.8776 19.4017 33.0151 21.2193C33.0924 22.1904 32.9893 23.2216 32.7143 24.2743Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_237_369">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Godaddy;
