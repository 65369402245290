import React from "react";
import styles from "../styles/logosStyles.module.css";
const Oracle = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_237_358)">
        <path
          d="M20.9968 21.342H23.9029L22.3663 18.8697L19.546 23.3397H18.2625L21.6929 17.9703C21.842 17.7534 22.0906 17.6223 22.3663 17.6223C22.6329 17.6223 22.8815 17.7488 23.0262 17.9613L26.4701 23.3397H25.1866L24.581 22.3408H21.6387L20.9969 21.342H20.9968ZM34.3298 22.3407V17.6765H33.2406V22.7973C33.2406 22.9374 33.2948 23.073 33.3987 23.177C33.5027 23.2809 33.6428 23.3397 33.7964 23.3397H38.7635L39.4054 22.3408H34.3297L34.3298 22.3407ZM16.3099 21.5046C17.3676 21.5046 18.2263 20.6504 18.2263 19.5928C18.2263 18.5352 17.3676 17.6764 16.3099 17.6764H11.5449V23.3397H12.6337V18.6754H16.2377C16.7439 18.6754 17.1506 19.0868 17.1506 19.593C17.1506 20.0991 16.7439 20.5104 16.2377 20.5104L13.1671 20.5059L16.4184 23.3397H18.0003L15.8128 21.5048H16.31L16.3099 21.5046ZM4.84234 23.3397C3.27898 23.3397 2.01025 22.0742 2.01025 20.5104C2.01025 18.9465 3.27898 17.6766 4.84234 17.6766H8.13398C9.69768 17.6766 10.9655 18.9465 10.9655 20.5104C10.9655 22.0742 9.69768 23.3397 8.1339 23.3397H4.84234ZM8.0608 22.3408C9.07363 22.3408 9.89396 21.5227 9.89396 20.5103C9.89396 19.498 9.07363 18.6754 8.0608 18.6754H4.91518C3.90269 18.6754 3.08193 19.498 3.08193 20.5104C3.08193 21.5227 3.90269 22.3408 4.9151 22.3408H8.06071H8.0608ZM28.7435 23.3397C27.1798 23.3397 25.9097 22.0742 25.9097 20.5104C25.9097 18.9465 27.1798 17.6766 28.7435 17.6766H32.6531L32.0158 18.6754H28.8158C27.8034 18.6754 26.9809 19.498 26.9809 20.5104C26.9809 21.5227 27.8034 22.3408 28.8158 22.3408H32.7434L32.1016 23.3397H28.7435ZM42.063 22.3408C41.2269 22.3408 40.5172 21.7804 40.3003 21.0075H44.9555L45.5973 20.0087H40.3003C40.5172 19.2403 41.2269 18.6754 42.063 18.6754H45.2584L45.9046 17.6764H41.9906C40.4268 17.6764 39.1568 18.9464 39.1568 20.5102C39.1568 22.0741 40.4268 23.3396 41.9906 23.3396H45.3487L45.9905 22.3408H42.0629H42.063Z"
          fill="#EA1B22"
        />
      </g>
      <defs>
        <clipPath id="clip0_237_358">
          <rect
            width="44.0002"
            height="5.75522"
            fill="white"
            transform="translate(2 17.6223)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Oracle;
