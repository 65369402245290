import React from "react";
import styles from "../styles/logosStyles.module.css";

const GooglePhotos = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_331)">
        <path
          d="M13 11.9941C19.072 11.9941 24 16.9221 24 22.9941V23.9999H3.00571C2.45257 23.9999 2 23.5473 2 22.9941C2 16.9221 6.928 11.9941 13 11.9941Z"
          fill="#FBBB05"
        />
        <path
          d="M36.0057 13C36.0057 19.072 31.0777 24 25.0057 24H24V3.00571C24 2.45257 24.4526 2 25.0057 2C31.0777 2 36.0057 6.928 36.0057 13Z"
          fill="#E94335"
        />
        <path
          d="M35 36.0057C28.928 36.0057 24 31.0777 24 25.0057V24H44.9943C45.5474 24 46 24.4526 46 25.0057C46 31.0777 41.072 36.0057 35 36.0057Z"
          fill="#4285F4"
        />
        <path
          d="M11.9946 35C11.9946 28.928 16.9226 24 22.9946 24H24.0003V44.9943C24.0003 45.5474 23.5478 46 22.9946 46C16.9226 46 11.9946 41.072 11.9946 35Z"
          fill="#0F9D58"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_331">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GooglePhotos;
