import React from "react";
import styles from "../styles/logosStyles.module.css";

const LinkedIn = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_333)">
        <path
          d="M40.2912 2H6.70878C4.6605 2 3 3.6605 3 5.70878V39.2911C3 41.3395 4.6605 43 6.70878 43H40.2911C42.3395 43 44 41.3395 44 39.2911V5.70878C44 3.6605 42.3395 2 40.2912 2ZM15.6871 37.4021C15.6871 37.9982 15.2039 38.4814 14.6078 38.4814H10.0135C9.41736 38.4814 8.93416 37.9982 8.93416 37.4021V18.1427C8.93416 17.5466 9.41736 17.0634 10.0135 17.0634H14.6078C15.2039 17.0634 15.6871 17.5466 15.6871 18.1427V37.4021ZM12.3106 15.2479C9.90013 15.2479 7.94597 13.2938 7.94597 10.8833C7.94597 8.47274 9.90013 6.51859 12.3106 6.51859C14.7212 6.51859 16.6753 8.47274 16.6753 10.8833C16.6753 13.2938 14.7213 15.2479 12.3106 15.2479ZM39.6971 37.489C39.6971 38.0371 39.2528 38.4814 38.7048 38.4814H33.7747C33.2267 38.4814 32.7823 38.0371 32.7823 37.489V28.4553C32.7823 27.1077 33.1776 22.5499 29.2605 22.5499C26.2221 22.5499 25.6058 25.6696 25.4821 27.0696V37.489C25.4821 38.0371 25.0378 38.4814 24.4897 38.4814H19.7215C19.1734 38.4814 18.7291 38.0371 18.7291 37.489V18.0558C18.7291 17.5078 19.1734 17.0634 19.7215 17.0634H24.4897C25.0377 17.0634 25.4821 17.5078 25.4821 18.0558V19.736C26.6087 18.0453 28.283 16.7403 31.848 16.7403C39.7423 16.7403 39.6971 24.1155 39.6971 28.1679V37.489Z"
          fill="#0077B7"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_333">
          <rect
            width="41"
            height="41"
            fill="white"
            transform="translate(3 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedIn;
