import React from "react";
import styles from "../styles/logosStyles.module.css";

const Vi = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_237_337)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.425 30.1097H40.8251V6.50186H32.4261V30.1116L32.425 30.1097ZM21.2814 6.50186L15.8425 28.5021L10.4047 6.50098H2.00464L7.84081 30.1107H23.8463L29.6806 6.50098H21.2814V6.50186Z"
          fill="#ED303B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.1986 37.4271C41.1986 39.9529 39.1516 41.9999 36.6258 41.9999C34.1 41.9999 32.053 39.9529 32.053 37.4271C32.053 34.9023 34.1 32.8542 36.6258 32.8542C39.1516 32.8542 41.1986 34.9013 41.1986 37.4271Z"
          fill="#FFD300"
        />
        <path
          d="M42.7603 8.15036V6.25394H42.051V6.00035H43.7561V6.25394H43.0447V8.15036H42.7603ZM44.0386 8.15036V6.00018H44.4675L44.9759 7.52275C45.023 7.66453 45.0576 7.77069 45.0784 7.8407C45.1034 7.76276 45.1411 7.64778 45.1933 7.49594L45.7079 6H46.0909V8.15019H45.8169V6.35058L45.1914 8.15036H44.9352L44.3127 6.31972V8.15036H44.0386Z"
          fill="#ED303B"
        />
      </g>
      <defs>
        <clipPath id="clip0_237_337">
          <rect
            width="44.096"
            height="36"
            fill="white"
            transform="translate(2 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Vi;
