import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Windows = () => {
    return (
        <svg
            // width="48" height="48"
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.25 22.625H25.375C24.55 22.625 24 22.075 24 21.25V6.5375C24 5.85 24.55 5.3 25.1 5.1625L42.975 2C43.3875 2 43.8 2 44.075 2.275C44.4875 2.55 44.625 2.9625 44.625 3.375V21.25C44.625 22.075 44.075 22.625 43.25 22.625Z" fill="#00ADEF" />
            <path d="M19.875 22.625H3.375C2.55 22.625 2 22.075 2 21.25V10.25C2 9.5625 2.55 9.0125 3.1 8.875L19.6 6.125C20.0125 6.125 20.425 6.125 20.7 6.4C21.1125 6.675 21.25 7.0875 21.25 7.5V21.25C21.25 22.075 20.7 22.625 19.875 22.625Z" fill="#00ADEF" />
            <path d="M43.25 46C43.1125 46 43.1125 46 42.975 46L25.1 42.8375C24.4125 42.7 24 42.15 24 41.4625V26.75C24 25.925 24.55 25.375 25.375 25.375H43.25C44.075 25.375 44.625 25.925 44.625 26.75V44.625C44.625 45.0375 44.4875 45.45 44.075 45.725C43.9375 45.8625 43.525 46 43.25 46Z" fill="#00ADEF" />
            <path d="M19.875 41.875C19.7375 41.875 19.7375 41.875 19.6 41.875L3.1 39.125C2.55 38.9875 2 38.4375 2 37.75V26.75C2 25.925 2.55 25.375 3.375 25.375H19.875C20.7 25.375 21.25 25.925 21.25 26.75V40.5C21.25 40.9125 21.1125 41.325 20.7 41.6C20.5625 41.7375 20.15 41.875 19.875 41.875Z" fill="#00ADEF" />
        </svg>

    )
}

export default Windows