import React from "react";
import styles from "../styles/logosStyles.module.css";

const GoogleDuo = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_282)">
        <g clipPath="url(#clip1_21_282)">
          <path
            d="M46.0005 25.2498C45.398 36.8622 35.7605 45.9997 24.0005 45.9997C11.853 45.9997 2.00049 36.1497 2.00049 23.9998C2.00049 11.8498 11.853 1.99976 24.0005 1.99976H42.5005C44.418 1.99976 46.0005 3.58226 46.0005 5.49976V25.2498Z"
            fill="#1A73E8"
          />
          <path
            opacity="0.2"
            d="M42.5005 1.99976H24.0005C11.8505 1.99976 2.00049 11.8448 2.00049 23.9873V23.9823C2.06799 11.8973 11.893 2.24976 24.0005 2.24976H42.5005C44.4205 2.24976 46.0005 3.83226 46.0005 5.74976V5.49976C46.0005 3.58226 44.4205 1.99976 42.5005 1.99976Z"
            fill="white"
          />
          <path
            d="M46.0005 25.2498V24.7498C45.398 36.3648 35.763 45.7498 24.0005 45.7498C11.893 45.7498 2.07049 35.8448 2.00299 23.7498C2.00299 23.7923 2.00049 23.9573 2.00049 23.9998C2.00049 36.1523 11.8505 46.0223 24.0005 45.9998C35.948 45.9773 45.468 36.8448 46.0005 24.9998"
            fill="#1A237E"
            fillOpacity="0.2"
          />
          <path
            d="M32.9995 28.2502C32.9995 30.3702 31.3495 31.7477 29.2495 31.7502H16.7495C14.652 31.7527 13.002 30.3852 12.9995 28.2677V29.5002C13.002 31.6202 14.652 33.2527 16.7495 33.2502H29.2495C31.347 33.2477 33.002 31.6202 32.9995 29.5002V28.2502ZM38.9995 29.2502C38.992 29.8677 38.172 30.2052 37.7495 29.7502L32.9995 25.0002V26.2502L37.7495 31.0002C38.2495 31.4777 38.9995 31.1777 38.9995 30.5002V29.2502Z"
            fill="#185ABC"
          />
          <path
            d="M37.75 17.2508L33 22.0008V25.0033L37.75 29.7508C38.1925 30.2208 39 29.8958 39 29.2508V17.7508C39 17.1208 38.2025 16.8058 37.75 17.2508Z"
            fill="#F1F3F4"
          />
          <path
            d="M29.2495 15H16.7495C14.652 15.0025 12.997 16.685 12.9995 18.75V28.25C13.002 30.315 14.652 32.0025 16.7495 32H29.2495C31.347 31.9975 33.002 30.315 32.9995 28.25V18.75C32.997 16.685 31.347 14.9975 29.2495 15Z"
            fill="white"
          />
          <path d="M33 25.3L33.0025 25L34 26V21L33 22V25.3Z" fill="#BDC1C6" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_21_282">
          <rect width="48" height="48" fill="white" />
        </clipPath>
        <clipPath id="clip1_21_282">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleDuo;
