import React from "react";
import styles from "../styles/logosStyles.module.css";

const Steam = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_237_357)">
        <path
          d="M3.23896 30.192C5.9439 39.2147 14.3113 45.7887 24.2143 45.7887C36.306 45.7887 46.1086 35.986 46.1086 23.8943C46.1086 11.8025 36.306 2 24.2143 2C12.6112 2 3.1168 11.0263 2.36694 22.4397C3.78397 24.819 4.33668 26.2905 3.23915 30.192H3.23896Z"
          fill="url(#paint0_linear_237_357)"
        />
        <path
          d="M23.0865 18.4107C23.0865 18.4475 23.0865 18.4843 23.0884 18.5189L17.7307 26.3017C16.8629 26.2622 15.992 26.4144 15.1662 26.7546C14.8077 26.9012 14.4643 27.0825 14.141 27.2958L2.37651 22.4528C2.37651 22.4528 2.10438 26.9312 3.23875 30.269L11.5556 33.7006C11.9732 35.5668 13.2534 37.2037 15.1421 37.9912C18.2323 39.2823 21.7954 37.8108 23.0808 34.7192C23.4154 33.9111 23.5714 33.0635 23.5488 32.2178L31.2146 26.7395C31.2767 26.7414 31.3406 26.7433 31.4026 26.7433C35.9901 26.7433 39.7187 23.0034 39.7187 18.4107C39.7187 13.8176 35.9901 10.0811 31.4026 10.0811C26.817 10.0811 23.0865 13.8176 23.0865 18.4107ZM21.801 34.1817C20.8061 36.5704 18.0611 37.7036 15.6751 36.7095C14.5744 36.2509 13.7433 35.4108 13.2639 34.4091L15.9711 35.5311C17.7307 36.2641 19.7495 35.4296 20.481 33.6706C21.2147 31.9096 20.3823 29.8874 18.6236 29.1545L15.8251 27.995C16.905 27.5853 18.1325 27.5702 19.2795 28.0476C20.4359 28.5287 21.3331 29.4345 21.8085 30.5922C22.284 31.7499 22.2821 33.0278 21.801 34.1817ZM31.4026 23.9619C28.3486 23.9619 25.8623 21.4718 25.8623 18.4107C25.8623 15.352 28.3486 12.8612 31.4026 12.8612C34.4584 12.8612 36.9448 15.352 36.9448 18.4107C36.9448 21.4718 34.4584 23.9619 31.4026 23.9619ZM27.2511 18.4022C27.2511 16.0995 29.1154 14.2318 31.412 14.2318C33.7104 14.2318 35.5747 16.0995 35.5747 18.4022C35.5747 20.7052 33.7104 22.5712 31.412 22.5712C29.1154 22.5712 27.2511 20.7052 27.2511 18.4022Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_237_357"
          x1="5123.36"
          y1="2"
          x2="5123.36"
          y2="10204.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#111D2E" />
          <stop offset="0.212" stopColor="#051839" />
          <stop offset="0.407" stopColor="#0A1B48" />
          <stop offset="0.581" stopColor="#132E62" />
          <stop offset="0.738" stopColor="#144B7E" />
          <stop offset="0.873" stopColor="#136497" />
          <stop offset="1" stopColor="#1387B8" />
        </linearGradient>
        <clipPath id="clip0_237_357">
          <rect
            width="44.2131"
            height="43.7887"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Steam;
