import React from "react";
import styles from "../styles/logosStyles.module.css";

const Android = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_295)">
        <path
          d="M17.6928 28.9321C19.2373 28.9321 20.4893 30.1842 20.4893 31.7286V43.7249C20.4893 45.2694 19.2373 46.5212 17.6928 46.5212C16.1485 46.5212 14.8965 45.2694 14.8965 43.7249V31.7286C14.8965 30.1844 16.1485 28.9323 17.6928 28.9323V28.9321Z"
          fill="#A4C639"
        />
        <path
          d="M11.3083 14.5095C11.2991 14.6156 11.291 14.7228 11.291 14.8312V33.0066C11.291 34.9613 12.8382 36.536 14.7589 36.536H33.2421C35.163 36.536 36.7102 34.9611 36.7102 33.0066V14.8312C36.7102 14.7228 36.7062 14.6152 36.6972 14.5095H11.3083Z"
          fill="#A4C639"
        />
        <path
          d="M30.308 28.9324C31.8525 28.9324 33.1045 30.1845 33.1045 31.7289V43.7252C33.1045 45.2697 31.8525 46.5215 30.308 46.5215C28.7638 46.5215 27.5117 45.2697 27.5117 43.7252V31.7289C27.5117 30.1847 28.7638 28.9326 30.308 28.9326V28.9324ZM6.797 16.1271C8.34125 16.1271 9.59331 17.3792 9.59331 18.9236V30.9199C9.59331 32.4644 8.34125 33.7164 6.797 33.7164C5.25255 33.7164 4.00049 32.4644 4.00049 30.9201V18.9238C4.00029 17.3794 5.25235 16.1271 6.797 16.1271ZM41.2028 16.1271C42.7471 16.1271 43.9992 17.3792 43.9992 18.9236V30.9199C43.9992 32.4644 42.7471 33.7164 41.2028 33.7164C39.6584 33.7164 38.4063 32.4644 38.4063 30.9201V18.9238C38.4063 17.3794 39.6584 16.1271 41.2028 16.1271V16.1271ZM11.3891 13.3157C11.4669 7.73289 16.3396 3.1555 22.5944 2.55225H25.4049C31.66 3.15569 36.5324 7.73328 36.6101 13.3157H11.3891Z"
          fill="#A4C639"
        />
        <path
          d="M12.3623 2.01343L15.3122 7.12298M35.6387 2.01343L32.6886 7.12298"
          stroke="#A4C639"
          strokeWidth="0.589111"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2436 8.31252C20.246 9.0929 19.6001 9.72737 18.8009 9.72953C18.0021 9.73149 17.3525 9.10075 17.3501 8.32038V8.31252C17.3479 7.53195 17.9938 6.89767 18.7928 6.89551C19.5917 6.89335 20.2413 7.5239 20.2436 8.30467V8.31252ZM31.0432 8.31252C31.0456 9.0929 30.3997 9.72737 29.6005 9.72953C28.8016 9.73149 28.152 9.10075 28.1497 8.32038V8.31252C28.1475 7.53195 28.7934 6.89767 29.5924 6.89551C30.3913 6.89335 31.0408 7.5239 31.0432 8.30467V8.31252Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_295">
          <rect
            width="40"
            height="44.8029"
            fill="white"
            transform="translate(4 1.71875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Android;
