import React from "react";
import styles from "../styles/logosStyles.module.css";

const Apple = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_263)">
        <path
          d="M24.4413 5.56827C27.1351 2.0178 30.8801 2.00049 30.8801 2.00049C30.8801 2.00049 31.4371 5.33853 28.761 8.55413C25.9036 11.9877 22.6557 11.4258 22.6557 11.4258C22.6557 11.4258 22.0459 8.72547 24.4413 5.56827ZM22.9983 13.7641C24.3842 13.7641 26.9562 11.8592 30.304 11.8592C36.0666 11.8592 38.3337 15.9597 38.3337 15.9597C38.3337 15.9597 33.8998 18.2267 33.8998 23.7273C33.8998 29.9326 39.4232 32.0711 39.4232 32.0711C39.4232 32.0711 35.5621 42.9386 30.3469 42.9386C27.9515 42.9386 26.0893 41.3244 23.5654 41.3244C20.9934 41.3244 18.4411 42.9989 16.7788 42.9989C12.0165 42.999 6 32.69 6 24.4033C6 16.2503 11.0925 11.9734 15.8691 11.9734C18.9743 11.9734 21.384 13.7641 22.9983 13.7641Z"
          fill="#999999"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_263">
          <rect
            width="33.4232"
            height="41"
            fill="white"
            transform="translate(6 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Apple;
