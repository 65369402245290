import React from "react";
import styles from "../styles/logosStyles.module.css";

const GooglePlay = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_271)">
        <path
          d="M30.9404 12.8172L13.2508 2.82696C12.3743 2.33021 11.5944 2.38316 11.1113 2.86775L25.9406 17.7985L28.7532 16.4628C30.2147 15.6391 32.4002 13.6445 30.9404 12.8172V12.8172Z"
          fill="url(#paint0_linear_21_271)"
        />
        <path
          d="M25.9041 17.762L29.5361 20.0421C30.9956 20.8693 32.2837 21.6768 30.8309 22.726L13.2764 32.7271C12.3336 33.2584 11.5257 33.1734 11.0527 32.5838L15.7418 26.957L25.9041 17.762Z"
          fill="url(#paint1_linear_21_271)"
        />
        <path
          d="M12.3569 40.3925C13.4379 40.3925 14.3027 41.1621 14.3027 42.3129C14.3027 43.4638 13.4379 44.2334 12.3569 44.2334C11.2759 44.2334 10.4111 43.4638 10.4111 42.3129C10.4111 41.1621 11.2759 40.3925 12.3569 40.3925ZM12.371 43.4815C13.065 43.4815 13.483 42.9182 13.483 42.3057C13.483 41.6935 13.065 41.13 12.371 41.13C11.677 41.13 11.2589 41.6934 11.2589 42.3057C11.2589 42.9181 11.677 43.4815 12.371 43.4815ZM16.6199 40.3925C17.7009 40.3925 18.5657 41.1621 18.5657 42.3129C18.5657 43.4638 17.7009 44.2334 16.6199 44.2334C15.539 44.2334 14.6742 43.4638 14.6742 42.3129C14.6742 41.1621 15.539 40.3925 16.6199 40.3925V40.3925ZM16.634 43.4815C17.3281 43.4815 17.746 42.9182 17.746 42.3057C17.746 41.6935 17.3281 41.13 16.634 41.13C15.94 41.13 15.522 41.6934 15.522 42.3057C15.522 42.9181 15.94 43.4815 16.634 43.4815ZM25.5483 42.8995C25.7261 43.2429 26.0621 43.488 26.5166 43.488C26.9504 43.488 27.2765 43.2648 27.4597 42.9457L28.1268 43.4059C27.7899 43.9246 27.2052 44.2332 26.5206 44.2332C25.4396 44.2332 24.5748 43.4636 24.5748 42.3127C24.5748 41.1619 25.4995 40.3136 26.5325 40.3581C26.5325 40.3581 27.7245 40.3704 28.1646 41.8021L25.5483 42.8995ZM27.1856 41.5294C27.0723 41.2768 26.8242 41.1028 26.4708 41.1028C25.7902 41.1028 25.4209 41.6526 25.4051 42.2593L27.1856 41.5294ZM21.9498 43.6619C21.6279 44.2187 20.9177 44.2333 20.9177 44.2333C19.8368 44.2333 18.972 43.4637 18.972 42.3128C18.972 41.162 19.8368 40.3923 20.9177 40.3923C20.9177 40.3923 21.5583 40.3818 21.8975 40.8517L22.202 41.1041L21.9498 43.6619ZM20.8954 43.4939C21.5667 43.4939 21.9534 42.9246 21.9534 42.3059C21.9534 41.6877 21.5667 41.1184 20.8954 41.1184C20.2242 41.1184 19.8198 41.6877 19.8198 42.3059C19.8198 42.9246 20.2242 43.4939 20.8954 43.4939Z"
          fill="#6E6F70"
        />
        <path
          d="M21.8965 40.9588L22.0221 41.5865V42.3578L21.9145 43.1649V44.0721C21.9145 44.6844 21.5911 45.2479 20.9088 45.2479C20.4133 45.2479 20.0387 44.9503 19.8701 44.5533L19.1355 44.8734C19.4265 45.5702 20.1013 45.9998 20.895 45.9998C21.9577 45.9998 22.7037 45.2301 22.7037 44.0793V40.4746H21.8966V40.9588H21.8965ZM23.3134 38.394H24.1475V44.1334H23.3134V38.394ZM34.4514 38.394H35.2854V44.1334H34.4514V38.394ZM10.0629 40.9537H7.30945V41.7561H9.25081C9.21272 42.692 8.51484 43.4534 7.25865 43.4534C6.02528 43.4534 5.08882 42.4536 5.08882 41.2201C5.08882 39.9866 6.02518 38.9868 7.25865 38.9868C7.8294 38.9859 8.37863 39.2045 8.7928 39.5971L9.34487 39.02C8.77879 38.4917 8.03293 38.1986 7.25865 38.2002C5.57675 38.2002 4.21338 39.5524 4.21338 41.2202C4.21338 42.888 5.57675 44.2401 7.25865 44.2401C7.25865 44.2401 10.3293 44.3923 10.0629 40.9537V40.9537ZM43.1056 40.4773H42.1637L41.1683 43.0121L40.1444 40.4773H39.2266L40.7605 43.9584L39.8674 45.8737H40.7559L43.1056 40.4773ZM32.1659 38.392H30.5215L30.5217 38.3941H30.0509V44.1335H30.8849V41.9097H32.1659C33.1633 41.9097 33.9718 41.1223 33.9718 40.1508C33.9718 39.1794 33.1633 38.392 32.1659 38.392V38.392ZM32.1088 41.1496H30.8849V39.1587H32.1088C32.6845 39.1587 33.1513 39.6045 33.1513 40.1542C33.1513 40.7039 32.6844 41.1496 32.1088 41.1496ZM37.4247 40.3418C36.6622 40.3418 36.1373 40.7011 35.8582 41.2196L36.6201 41.5231C36.7698 41.2706 37.0659 41.0993 37.4362 41.0993C37.9836 41.0993 38.3585 41.4637 38.3585 41.9131C38.3585 41.9131 37.9805 41.699 37.375 41.699C35.7473 41.699 35.7967 42.9701 35.7967 42.9701C35.7967 44.3086 37.2259 44.2419 37.2259 44.2419C37.9458 44.2419 38.3359 43.6418 38.3359 43.6418V44.1335H39.1609V41.8792C39.1611 41.0301 38.458 40.3418 37.4247 40.3418ZM38.3403 42.607C38.1317 43.6461 37.1034 43.4816 37.1034 43.4816C37.0001 43.4686 36.9031 43.4252 36.8245 43.3569C36.7459 43.2887 36.6894 43.1986 36.6622 43.0982C36.5863 42.8185 36.7395 42.5617 37.0313 42.4546C37.0313 42.4546 37.6666 42.2283 38.3436 42.587L38.3403 42.607Z"
          fill="#6E6F70"
        />
        <path
          d="M36.9792 16.2225L30.8809 12.7913L25.9058 17.7648L30.8303 22.7278L36.9756 19.2242C37.1583 19.1212 37.3183 19.0101 37.4553 18.8927C37.5923 18.7753 37.7067 18.6517 37.7981 18.524C37.821 18.4921 37.8425 18.4599 37.8625 18.4276C38.3226 17.6821 38.0284 16.8171 36.9792 16.2225V16.2225Z"
          fill="url(#paint2_linear_21_271)"
        />
        <path
          d="M11.1217 2.86035C10.8 3.18244 10.6091 3.69469 10.6099 4.36512L10.639 31.1655C10.6397 31.7612 10.7915 32.2592 11.0518 32.5838L25.9062 17.7606L11.1217 2.86035Z"
          fill="url(#paint3_linear_21_271)"
        />
        <path
          d="M37.69 16.7898C38.177 17.5435 37.9261 18.5285 36.7928 19.1478L13.1166 32.6461C12.2055 33.1596 11.4187 32.961 10.9385 32.4232C11.3879 33.1328 12.2253 33.3167 13.2854 32.7225L36.9617 19.2243C38.1494 18.5887 38.3696 17.5823 37.69 16.7898V16.7898Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_21_271"
          x1="11.1113"
          y1="10.1386"
          x2="31.4224"
          y2="10.1386"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.077" stopColor="#54B06A" />
          <stop offset="0.374" stopColor="#65B362" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_21_271"
          x1="11.0528"
          y1="25.4211"
          x2="31.4684"
          y2="25.4211"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.121" stopColor="#DF2253" />
          <stop offset="0.451" stopColor="#E63D49" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_21_271"
          x1="25.9058"
          y1="17.7596"
          x2="38.0729"
          y2="17.7596"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.077" stopColor="#FCC40D" />
          <stop offset="0.286" stopColor="#FDCE0F" />
          <stop offset="0.683" stopColor="#FEE40E" />
          <stop offset="0.769" stopColor="#FEE90F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_21_271"
          x1="18.258"
          y1="2.86035"
          x2="18.258"
          y2="32.5839"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.198" stopColor="#48BCE8" />
          <stop offset="0.593" stopColor="#61C4E2" />
        </linearGradient>
        <clipPath id="clip0_21_271">
          <rect
            width="39.3184"
            height="44"
            fill="white"
            transform="translate(4 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GooglePlay;
