import React from "react";
import styles from "../styles/logosStyles.module.css";

const Dropbox = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_327)">
        <path
          d="M13.0196 4.76733L2 11.8365L13.0196 18.8017L24.0392 11.8365L13.0196 4.76733ZM35.0588 4.76733L24.0392 11.8365L35.0588 18.8017L46.0784 11.8365L35.0588 4.76733ZM2 25.8709L13.0196 32.9401L24.0392 25.8709L13.0196 18.8017L2 25.8709ZM35.0588 18.8017L24.0392 25.8709L35.0588 32.9401L46.0784 25.8709L35.0588 18.8017ZM13.0196 35.2272L24.0392 42.2964L35.0588 35.2272L24.0392 28.262L13.0196 35.2272Z"
          fill="#0062FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_327">
          <rect
            width="44.0786"
            height="41.0636"
            fill="white"
            transform="translate(2 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Dropbox;
