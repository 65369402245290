import React from "react";
import styles from "../styles/logosStyles.module.css";

const Gmail = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_254)">
        <path
          d="M12.1548 19.9016C13.8646 14.7208 18.7333 11 24.5001 11C27.6001 11 30.4001 12.1 32.6001 13.9L39.0001 7.5C35.1001 4.1 30.1001 2 24.5001 2C15.8286 2 8.36266 6.94688 4.77344 14.1917L12.1548 19.9016Z"
          fill="#EA4335"
        />
        <path
          d="M31.9074 35.0228C29.9094 36.3129 27.3706 36.9997 24.4994 36.9997C18.7546 36.9997 13.9011 33.3073 12.1739 28.1575L4.76807 33.7805C8.35283 41.038 15.8186 45.9997 24.4994 45.9997C29.8763 45.9997 35.0142 44.0882 38.8621 40.4989L31.9074 35.0228Z"
          fill="#34A853"
        />
        <path
          d="M38.8627 40.4992C42.8868 36.7455 45.5 31.1567 45.5 24C45.5 22.7 45.3 21.3 45 20H24.5V28.5H36.3C35.7178 31.3583 34.1549 33.5722 31.908 35.0231L38.8627 40.4992Z"
          fill="#4A90E2"
        />
        <path
          d="M12.1745 28.1575C11.7369 26.853 11.5 25.4549 11.5 23.9997C11.5 22.5665 11.7298 21.1888 12.1547 19.9013L4.77332 14.1914C3.30041 17.1438 2.5 20.4712 2.5 23.9997C2.5 27.5189 3.31543 30.8383 4.76868 33.7805L12.1745 28.1575Z"
          fill="#FBBC05"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_254">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2.5 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Gmail;
