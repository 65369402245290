import React from "react";
import styles from "../styles/logosStyles.module.css";

const Slack = () => {
  return (
    <svg
      //  width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_296)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1001 2C15.6727 2.00179 13.7082 3.9717 13.71 6.3991C13.7082 8.8265 15.6745 10.7964 18.1019 10.7982H22.4939V6.4009C22.4957 3.9735 20.5293 2.00359 18.1001 2C18.1019 2 18.1019 2 18.1001 2M18.1001 13.7333H6.39193C3.96453 13.7351 1.99821 15.705 2 18.1324C1.99642 20.5598 3.96274 22.5297 6.39014 22.5333H18.1001C20.5275 22.5315 22.4939 20.5616 22.4921 18.1342C22.4939 15.705 20.5275 13.7351 18.1001 13.7333V13.7333Z"
          fill="#36C5F0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.912 18.1324C45.9138 15.705 43.9475 13.7351 41.5201 13.7333C39.0927 13.7351 37.1263 15.705 37.1281 18.1324V22.5333H41.5201C43.9475 22.5315 45.9138 20.5616 45.912 18.1324ZM34.202 18.1324V6.3991C34.2038 3.9735 32.2393 2.00359 29.8119 2C27.3845 2.00179 25.4181 3.9717 25.4199 6.3991V18.1324C25.4163 20.5598 27.3827 22.5297 29.8101 22.5333C32.2375 22.5315 34.2038 20.5616 34.202 18.1324Z"
          fill="#2EB67D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.8104 46.0002C32.2378 45.9984 34.2041 44.0285 34.2023 41.6011C34.2041 39.1737 32.2378 37.2038 29.8104 37.202H25.4185V41.6011C25.4167 44.0267 27.383 45.9966 29.8104 46.0002ZM29.8104 34.2651H41.5204C43.9478 34.2633 45.9141 32.2934 45.9123 29.866C45.9159 27.4386 43.9496 25.4687 41.5222 25.4651H29.8122C27.3848 25.4669 25.4185 27.4368 25.4203 29.8642C25.4185 32.2934 27.383 34.2633 29.8104 34.2651V34.2651Z"
          fill="#ECB22E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 29.8659C1.99821 32.2933 3.96453 34.2632 6.39193 34.265C8.81933 34.2632 10.7856 32.2933 10.7839 29.8659V25.4668H6.39193C3.96453 25.4686 1.99821 27.4385 2 29.8659ZM13.71 29.8659V41.5992C13.7064 44.0266 15.6727 45.9965 18.1001 46.0001C20.5275 45.9983 22.4939 44.0284 22.4921 41.601V29.8695C22.4957 27.4421 20.5293 25.4722 18.1019 25.4686C15.6727 25.4686 13.7082 27.4385 13.71 29.8659C13.71 29.8677 13.71 29.8659 13.71 29.8659Z"
          fill="#E01E5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_296">
          <rect
            width="43.9121"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Slack;
