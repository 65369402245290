import React from 'react'
import styles from "../styles/logosStyles.module.css"

const AxisBank = () => {
    return (
        <svg
            // width="48" height="48"
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.9227 39.0154C30.7678 40.5902 31.6139 42.1641 32.4591 43.7389C32.168 43.7389 31.8613 43.7244 31.5703 43.7389C31.3665 43.3886 31.1909 43.0248 31.0017 42.6745C30.2584 42.6454 29.5006 42.6745 28.7563 42.6599C28.5817 43.0248 28.3769 43.3741 28.1877 43.7244C27.8966 43.7244 27.6045 43.7244 27.3135 43.7244C28.1586 42.1495 29.0183 40.5757 29.9227 39.0154ZM29.1202 42.004C29.6306 42.004 30.141 42.004 30.6503 42.0185C30.3884 41.5372 30.169 41.0559 29.8925 40.5902C29.6306 41.0414 29.3686 41.5227 29.1202 42.004Z" fill="#AE285D" />
            <path d="M32.9386 39C34.2068 40.1081 35.4896 41.2162 36.7432 42.3379C36.7723 41.3171 36.7432 40.3119 36.7723 39.2911C37.0488 39.2911 37.3118 39.3056 37.5883 39.3056C37.5738 40.8659 37.5883 42.4398 37.5883 44.0001C36.7578 43.3005 35.9407 42.5718 35.1247 41.8576C34.658 41.4636 34.2068 41.0707 33.7692 40.6476C33.7546 41.683 33.7692 42.7027 33.7546 43.7381C33.4781 43.7381 33.2151 43.7381 32.9386 43.7381C32.9386 42.1487 32.924 40.5749 32.9386 39Z" fill="#AE285D" />
            <path d="M6 43.7235C6.87423 42.1487 7.73495 40.5603 8.63828 39C9.48341 40.5749 10.315 42.1487 11.1747 43.7235C10.8691 43.7235 10.577 43.7235 10.2714 43.7235C10.0822 43.3732 9.90649 43.024 9.73185 42.6591C8.97405 42.6445 8.23079 42.6591 7.47195 42.6591C7.28276 43.0094 7.09253 43.3732 6.88878 43.7235C6.58317 43.7381 6.29106 43.7381 6 43.7235ZM7.83682 42.0032C8.34722 42.0032 8.87218 42.0032 9.38154 42.0032C9.11958 41.5219 8.90024 41.0406 8.62373 40.5749C8.34722 41.0551 8.09878 41.5219 7.83682 42.0032Z" fill="#AE285D" />
            <path d="M18.537 39.424C18.9747 39.2057 19.4705 39.1911 19.9362 39.2785C20.3447 39.3658 20.6649 39.6579 20.9134 39.9635C20.6951 40.0799 20.4757 40.1818 20.2574 40.2993C19.9664 39.8471 19.2803 39.7015 18.8582 40.0519C18.6399 40.2119 18.6545 40.6059 18.9019 40.7223C19.4705 41.028 20.1556 41.1018 20.6514 41.5238C21.1618 41.9614 21.1472 42.8211 20.666 43.2733C20.1556 43.7837 19.3541 43.9001 18.6836 43.7109C18.115 43.5654 17.6919 43.055 17.6191 42.4864C17.8957 42.4282 18.1732 42.3845 18.4497 42.3409C18.4788 42.6028 18.5807 42.9095 18.8437 43.0405C19.2377 43.2588 19.8208 43.2005 20.0973 42.8076C20.2865 42.5457 20.2283 42.1371 19.9664 41.9479C19.4123 41.5831 18.6836 41.554 18.1732 41.0883C17.9112 40.87 17.8083 40.4905 17.8967 40.1548C17.9684 39.8169 18.246 39.5695 18.537 39.424Z" fill="#AE285D" />
            <path d="M11.44 39.3232C11.7456 39.2795 12.0668 39.2941 12.3735 39.2941C12.7092 39.7899 13.0439 40.2858 13.3652 40.7806C13.7155 40.2847 14.0502 39.7889 14.4005 39.3086C14.7217 39.2941 15.0419 39.2941 15.3631 39.3086C14.8527 40.0082 14.3423 40.7078 13.8475 41.422C14.3579 42.2089 14.9265 42.9521 15.4369 43.7546C15.1313 43.7546 14.8246 43.7546 14.519 43.7546C14.125 43.2006 13.7467 42.632 13.3672 42.0779C12.9733 42.632 12.5658 43.186 12.1718 43.7546C11.8662 43.7546 11.5595 43.7546 11.2539 43.7546C11.7934 42.9677 12.3475 42.2099 12.9161 41.4365C12.4161 40.7224 11.9213 40.0228 11.44 39.3232Z" fill="#AE285D" />
            <path d="M16.1205 39.3078C16.397 39.2932 16.6746 39.2932 16.9365 39.3078C16.9365 40.7797 16.9365 42.2673 16.9365 43.7392C16.66 43.7392 16.3825 43.7392 16.106 43.7392C16.1205 42.2527 16.106 40.7808 16.1205 39.3078Z" fill="#AE285D" />
            <path d="M24.1225 39.2928C24.8221 39.3219 25.5799 39.1909 26.2358 39.5402C26.8627 39.8759 27.0082 40.8812 26.425 41.3333C26.7899 41.4789 27.1537 41.7564 27.241 42.1494C27.4011 42.6452 27.1246 43.1847 26.687 43.4467C25.9001 43.8843 24.9811 43.6795 24.1215 43.7377C24.108 42.2668 24.1225 40.7803 24.1225 39.2928ZM24.9396 39.9352C24.9396 40.3583 24.9396 40.7803 24.9396 41.2034C25.2452 41.1889 25.5809 41.2034 25.8429 41.0142C26.1049 40.7814 26.1049 40.2855 25.7992 40.0807C25.5518 39.9196 25.2306 39.9352 24.9396 39.9352ZM24.9396 41.7855C24.9396 42.2232 24.9396 42.6452 24.9396 43.0828C25.3481 43.0683 25.7847 43.1411 26.1641 42.9373C26.4999 42.7772 26.5581 42.2377 26.2514 42.0194C25.8866 41.7128 25.3907 41.7855 24.9396 41.7855Z" fill="#AE285D" />
            <path d="M38.5556 39.2909C38.8321 39.2909 39.0951 39.2909 39.3716 39.2909C39.3716 39.8741 39.3716 40.4573 39.3716 41.0404C40.0421 40.4427 40.7271 39.8741 41.4122 39.2764C41.7916 39.2764 42.1554 39.2764 42.5348 39.2764C41.7334 39.9323 40.9309 40.6028 40.1294 41.2878C40.9163 42.1184 41.7188 42.9063 42.5203 43.7078C42.1554 43.7078 41.777 43.7369 41.4122 43.6933C40.7708 43.0519 40.1439 42.3959 39.5171 41.74C39.4735 41.7982 39.4298 41.8564 39.3861 41.9147C39.3716 42.5124 39.3861 43.1101 39.3716 43.7078C39.0951 43.7078 38.8175 43.7078 38.541 43.7078C38.541 42.2504 38.541 40.7785 38.5556 39.2909Z" fill="#AE285D" />
            <path d="M24.0792 6C25.7313 8.8283 27.3834 11.6249 28.9743 14.4532C26.3681 19.0605 23.7619 23.7018 21.0605 28.2774C20.0112 30.0247 19.0254 31.806 17.9784 33.5533C14.6424 33.585 11.3042 33.585 8 33.5533C8.09518 33.3312 8.19037 33.1091 8.31728 32.887C13.5909 23.9239 18.8668 14.9948 24.0792 6Z" fill="#AE285D" />
            <path d="M24.0786 23.16C27.4146 23.16 30.7528 23.1283 34.0887 23.1918C36.0898 26.6569 38.1249 30.088 40.0943 33.5849C36.7584 33.6166 33.4202 33.5849 30.0842 33.5849C28.1148 30.088 26.048 26.6546 24.0786 23.16Z" fill="#AE285D" />
        </svg>



    )
}

export default AxisBank