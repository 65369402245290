import React from "react";
import styles from "../styles/logosStyles.module.css";

const Facebook = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_297)">
        <path
          d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z"
          fill="#3C5A9A"
        />
        <path
          d="M31.153 8.75269H26.2795C23.3874 8.75269 20.1705 9.96907 20.1705 14.1613C20.1846 15.6221 20.1705 17.021 20.1705 18.5955H16.8247V23.9196H20.274V39.2469H26.6124V23.8185H30.7959L31.1744 18.5805H26.5031C26.5031 18.5805 26.5136 16.2505 26.5031 15.5738C26.5031 13.9171 28.227 14.012 28.3307 14.012C29.151 14.012 30.746 14.0144 31.1554 14.012V8.75269H31.153Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_297">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Facebook;
