import React from 'react'
import styles from "../styles/logosStyles.module.css"

const Upwork = () => {
    return (
        <svg
            // width="48" height="48" 
            className={styles.svgStyles}
            viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0404 25.7303C13.3254 25.7303 12.6554 25.4276 12.0466 24.9347L12.1948 24.239L12.2012 24.2132C12.3333 23.4724 12.752 22.229 14.0404 22.229C15.0068 22.229 15.7927 23.015 15.7927 23.9813C15.7895 24.9444 15.0035 25.7303 14.0404 25.7303ZM14.0404 20.4542C12.3945 20.4542 11.1189 21.5236 10.6003 23.2823C9.80793 22.0937 9.20881 20.6668 8.85772 19.4653H7.08612V24.0747C7.08612 24.983 6.34527 25.7239 5.43692 25.7239C4.52858 25.7239 3.78773 24.983 3.78773 24.0747V19.4653H2.01613V24.0747C2.00969 25.9623 3.54615 27.5116 5.4337 27.5116C7.32126 27.5116 8.85772 25.9623 8.85772 24.0747V23.3016C9.20237 24.0199 9.62433 24.7447 10.1365 25.3889L9.05098 30.4911H10.8644L11.6504 26.7901C12.3397 27.2314 13.1321 27.5084 14.0404 27.5084C15.9828 27.5084 17.5643 25.9172 17.5643 23.9748C17.5643 22.0358 15.9828 20.4542 14.0404 20.4542Z" fill="#6FDA44" />
            <path d="M22.9722 20.6541L24.1157 25.2635L25.3751 20.6541H26.8761L24.9403 27.3153H23.4393L22.2507 22.6769L21.0653 27.3121H19.5643L17.6284 20.6509H19.1294L20.3889 25.2602L21.5324 20.6509H22.9722V20.6541ZM30.4677 20.4544C28.5189 20.4544 26.9406 22.0359 26.9406 23.9815C26.9406 25.9302 28.5221 27.5086 30.4677 27.5086C32.4164 27.5086 33.998 25.9302 33.998 23.9815C33.998 22.0327 32.4164 20.4544 30.4677 20.4544ZM30.4677 26.0591C29.321 26.0591 28.3933 25.1314 28.3933 23.9847C28.3933 22.838 29.3242 21.9103 30.4677 21.9103C31.6144 21.9103 32.542 22.838 32.542 23.9847C32.542 25.1282 31.6144 26.0591 30.4677 26.0591ZM38.2852 22.1455C37.277 22.1455 36.4621 22.9636 36.4621 23.9686V27.3121H34.9514V20.6541H36.4621V21.6784C36.4621 21.6784 37.1063 20.6509 38.4302 20.6509H38.894V22.1455H38.2852ZM43.3552 23.7238C44.4278 23.1182 45.1558 21.9683 45.1558 20.6509H43.6451C43.6451 21.7621 42.7432 22.6641 41.6319 22.6641H41.429V17.4395H39.9183V27.3121H41.429V24.1747H41.6094C41.7576 24.1747 41.9508 24.2714 42.0378 24.3906L44.183 27.3121H45.9933L43.3552 23.7238Z" fill="black" />
        </svg>

    )
}

export default Upwork