import React from "react";
import styles from "../styles/logosStyles.module.css";

const Ikea = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_237_360)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2H46V46H2V2Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.64697 16.4724H45.3529V31.528H2.64697V16.4724ZM3.08137 24.0365C3.08137 20.2187 12.4792 17.1239 24.0724 17.1239C35.6655 17.1239 45.0632 20.2187 45.0632 24.0365C45.0632 27.8543 35.6655 30.9492 24.0724 30.9492C12.4794 30.9492 3.08137 27.8543 3.08137 24.0365Z"
          fill="#2360A5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.08105 24.0364C3.08105 20.2186 12.4788 17.1238 24.0721 17.1238C35.6652 17.1238 45.0629 20.2186 45.0629 24.0364C45.0629 27.8542 35.6652 30.9491 24.0721 30.9491C12.4791 30.9491 3.08105 27.8542 3.08105 24.0364Z"
          fill="#F6D33C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.81189 21.1864H12.5432C12.5432 21.1864 12.4436 21.343 12.4436 21.5386V26.342C12.4436 26.6029 12.5555 26.8248 12.5555 26.8248H8.79956C8.79956 26.8248 8.91141 26.6027 8.91141 26.3808V21.5776C8.91141 21.395 8.81189 21.1864 8.81189 21.1864ZM14.4008 21.1991H17.9488C17.9488 21.1991 17.8492 21.3662 17.8492 21.5208V23.2442L19.1065 21.6365C19.1065 21.6365 19.1814 21.5208 19.1814 21.405V21.2249H22.7915L21.2602 23.3599C21.2602 23.3599 21.1856 23.4499 21.1856 23.5142C21.1856 23.5786 21.2479 23.6685 21.2479 23.6685L23.2149 26.7938H18.8698C18.8698 26.7938 18.9447 26.6783 18.9447 26.5623C18.9447 26.4468 18.8698 26.305 18.8698 26.305L17.8365 24.6202V26.4468C17.8365 26.6011 17.9485 26.8065 17.9485 26.8065H14.4008C14.4008 26.8065 14.5003 26.6652 14.5003 26.5109V21.5205C14.5003 21.3665 14.4008 21.1991 14.4008 21.1991ZM24.2716 21.2382H30.6181V22.9429C30.6181 22.9429 30.4811 22.8166 30.3074 22.8166H27.7063V23.2837H29.9712V24.7864C29.9712 24.7864 29.8219 24.6601 29.66 24.6601H27.6814V25.1779H30.3444C30.4937 25.1779 30.5811 24.9379 30.5811 24.9379V26.8065H24.2716C24.2716 26.8065 24.3839 26.6424 24.3839 26.5287V21.655C24.3841 21.4527 24.2716 21.2382 24.2716 21.2382ZM33.426 21.177H38.5004C38.5004 21.177 38.4009 21.4119 38.4009 21.5425C38.4009 21.673 38.4878 21.9079 38.4878 21.9079L40.5151 26.8157H36.7094C36.7094 26.8157 36.7964 26.6721 36.7464 26.5548C36.6416 26.2941 36.538 26.033 36.4357 25.7713H34.8687C34.8687 25.7713 34.5575 26.4632 34.5575 26.594C34.5575 26.7241 34.6575 26.8289 34.6575 26.8289H31.5229L33.4881 21.699C33.4881 21.699 33.563 21.5945 33.563 21.4642C33.563 21.3338 33.426 21.177 33.426 21.177ZM35.5777 23.6832L35.2044 24.6882H36.0253L35.5777 23.6832Z"
          fill="#2360A5"
        />
      </g>
      <defs>
        <clipPath id="clip0_237_360">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ikea;
