import React from "react";
import styles from "../styles/logosStyles.module.css";

const Firebase = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_304)">
        <path
          d="M29.0115 18.0088L24.5789 22.136L20.4644 13.8332L22.5899 9.05926C23.1271 8.10699 24.0057 8.09441 24.543 9.05926L29.0115 18.0088Z"
          fill="#FFA000"
        />
        <path
          d="M24.5803 22.1342L8 37.5557L20.4658 13.8333L24.5803 22.1342Z"
          fill="#F57F17"
        />
        <path
          d="M33.8713 11.6971C34.6655 10.9406 35.483 11.1958 35.6896 12.2702L40 37.3474L25.7033 45.9304C25.202 46.2107 23.8724 46.3203 23.8724 46.3203C23.8724 46.3203 22.6632 46.173 22.1997 45.9178L8 37.5558L33.8713 11.6971Z"
          fill="#FFCA28"
        />
        <path
          d="M20.4658 13.8329L8 37.5553L13.5555 2.88178C13.764 1.80733 14.373 1.69773 14.9229 2.65L20.4658 13.8329Z"
          fill="#FFA000"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_304">
          <rect
            width="32"
            height="44.3221"
            fill="white"
            transform="translate(8 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Firebase;
