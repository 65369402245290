import React from "react";
import styles from "../styles/logosStyles.module.css";

const MSoffice = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_312)">
        <path
          d="M6 37.495V10.7444L29.6633 2L42.5229 6.11345V42.1242L29.6633 46.0018L6 37.495L29.6633 40.3429V9.2025L14.2304 12.8037V33.8955L6 37.495Z"
          fill="#EB3C00"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_312">
          <rect
            width="36.5229"
            height="44"
            fill="white"
            transform="translate(6 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MSoffice;
