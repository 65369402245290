import React from "react";
import styles from "../styles/logosStyles.module.css";
const Gitlab = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_206_18)">
        <path
          d="M24.0127 44.5752L32.1087 19.6584H15.9165L24.0127 44.5752Z"
          fill="#E24329"
        />
        <path
          d="M24.0129 44.5749L15.9167 19.6582H4.57056L24.0129 44.5749Z"
          fill="#FC6D26"
        />
        <path
          d="M4.57035 19.6582L2.11013 27.2303C2.00074 27.567 2.00072 27.9296 2.11009 28.2662C2.21945 28.6028 2.43257 28.8962 2.71891 29.1043L24.0127 44.5751L4.57018 19.6584L4.57035 19.6582Z"
          fill="#FCA326"
        />
        <path
          d="M4.57056 19.6584H15.9167L11.0408 4.65113C10.7899 3.8789 9.69742 3.87907 9.44665 4.65113L4.57056 19.6584Z"
          fill="#E24329"
        />
        <path
          d="M24.0131 44.5749L32.1091 19.6582H43.4554L24.0131 44.5749Z"
          fill="#FC6D26"
        />
        <path
          d="M43.4553 19.6582L45.9155 27.2303C46.0249 27.567 46.0248 27.9296 45.9155 28.2662C45.8061 28.6029 45.5929 28.8962 45.3065 29.1043L24.0129 44.5751L43.4553 19.6584V19.6582Z"
          fill="#FCA326"
        />
        <path
          d="M43.4555 19.6584H32.1091L36.9852 4.65113C37.2362 3.8789 38.3286 3.87907 38.5794 4.65113L43.4555 19.6584Z"
          fill="#E24329"
        />
      </g>
      <defs>
        <clipPath id="clip0_206_18">
          <rect
            width="44"
            height="40.568"
            fill="white"
            transform="translate(2 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Gitlab;
