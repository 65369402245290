import React from "react";
import styles from "../styles/logosStyles.module.css";

const Netflix = () => {
  return (
    <svg
      // width="48" height="48"
      className={styles.svgStyles}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_245)">
        <path
          d="M8.05013 29.1282C7.38824 29.2444 6.7147 29.2793 6.01796 29.3721L3.89282 23.1478V29.6392C3.23093 29.7089 2.62709 29.8018 2 29.8947V18.0034H3.76516L6.18052 24.7504V18.0034H8.05013V29.1282ZM11.7081 22.3581C12.4281 22.3581 13.5313 22.3233 14.1932 22.3233V24.1812C13.3687 24.1812 12.4049 24.1812 11.7081 24.2161V26.9799C12.7997 26.9103 13.8913 26.8173 14.9944 26.7824V28.5707L9.85005 28.9772V18.0034H14.9944V19.8614H11.7081V22.3581ZM21.9039 19.8615H19.9762V28.4083C19.3491 28.4083 18.7221 28.4083 18.1183 28.4315V19.8615H16.1906V18.0034H21.904L21.9039 19.8615V19.8615ZM24.9231 22.2304H27.4663V24.0884H24.9231V28.3038H23.0998V18.0034H28.2908V19.8614H24.9231V22.2304ZM31.3101 26.678C32.3668 26.7012 33.4351 26.7826 34.4687 26.8405V28.6753C32.8081 28.5708 31.1475 28.4664 29.4521 28.4315V18.0034H31.3101V26.678ZM36.0364 28.8031C36.6286 28.838 37.2557 28.8728 37.8595 28.9424V18.0034H36.0364V28.8031ZM46 18.0034L43.6426 23.6587L46 29.8947C45.3032 29.8018 44.6064 29.674 43.9097 29.5579L42.5743 26.1206L41.2157 29.2793C40.5421 29.163 39.8918 29.1282 39.2184 29.0353L41.6105 23.589L39.4505 18.0034H41.4478L42.6671 21.1272L43.9678 18.0034H46Z"
          fill="#D81F26"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_245">
          <rect
            width="44"
            height="11.8976"
            fill="white"
            transform="translate(2 18)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Netflix;
