import React from "react";
import styles from "../styles/logosStyles.module.css";
const Sbi = () => {
  return (
    <svg
      className={styles.svgStyles}
      // width="48" height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16_10)">
        <path
          d="M45 24.0083C45.0028 35.6062 35.603 45.0105 24.005 45.0133C12.4071 45.016 3.00269 35.6162 3 24.0182V24.0083C2.99723 12.4103 12.397 3.00603 23.9951 3.00317C35.5931 3.00049 44.9973 12.4003 45.0001 23.9983L45 24.0083Z"
          fill="#156DD1"
        />
        <path
          d="M27.78 24.0084C27.78 26.0961 26.0876 27.7884 24 27.7884C21.9122 27.7884 20.22 26.0961 20.22 24.0084C20.22 21.9207 21.9122 20.2284 24 20.2284C26.0876 20.2284 27.78 21.9207 27.78 24.0084Z"
          fill="white"
        />
        <path d="M22.6968 26.76H25.3037V45.0085H22.6968V26.76Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_16_10">
          <rect
            width="42"
            height="42.0168"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Sbi;
